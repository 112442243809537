export type BeneficialOwnerId = number & { isOwnerId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type DocumentId = number & { isDocumentId: true };

export enum Country {
  BELGIUM = "BE",
  CZECH_REPUBLIC = "CZ",
  NETHERLANDS = "NL",
  SLOVAKIA = "SK",
  LUXEMBURG = "LU",
  UNITED_KINGDOM = "GB",
  FRANCE = "FR",
  GERMANY = "DE",
}

export enum Language {
  FRENCH = "fr",
  GERMAN = "de",
  DUTCH = "nl",
  ENGLISH = "en",
  CZECH = "cs",
  SLOVAK = "sk",
}

export const ACTIVATED_LANGUAGES = [
  Language.FRENCH,
  Language.DUTCH,
  Language.ENGLISH,
  Language.CZECH,
];

export interface ContractDocument {
  documentId: DocumentId;
  confirmed?: UTCDate;
  fileAvailable: boolean;
  uploaded?: UTCDate;
  fileName?: string;
  mimeType?: string;
  documentType: DocumentType;
  documentDescription?: string; //Den här är satt om sales har skrivit in ett custom dokument, annrs bör språkfil används
}

export interface Address {
  street: string;
  postalCode: string;
  city: string;
  country: Country;
}

export interface CompanyDetails {
  legalName: string;
  companyId: string;
  address: Address;
}

export enum IdDocumentType {
  PASSPORT = "passport",
  ID_CARD = "id-card",
}

export interface BeneficialOwner {
  title?: string;
  firstName: string;
  lastName: string;
  address: Address;
  ownership?: number;
  nationality?: string;
  dateOfBirth?: string;
}

export interface IndirectOwnership {
  id: string;
  name: string;
  parentCompanyId?: string;
  parentOwnership?: number;
  companyRegistrationId: string;
  address: Address;
  country: Country;
}

export interface Contact {
  phoneNumber?: string;
  email: string;
  firstName: string;
  lastName: string;
  title?: string;
  dateOfBirth?: string;
}

export interface SupportContact {
  name: string;
  email: string;
}

export enum BeneficialOwnerType {
  OWNERSHIP = "OWNERSHIP",
  ROLE = "ROLE",
}

export interface Contract {
  country: string;
  language: string;
  companyDetails: CompanyDetails;
  beneficialOwner: BeneficialOwner;
  beneficialOwnerType: BeneficialOwnerType;
  indirectOwnership: IndirectOwnership[];
  supportContact: SupportContact;
}

export enum Validity {
  MISSING = "has-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "",
}
