import { FunctionComponent } from "react";
import { Button } from "../../components/interactions/Buttons/Button";
import { Modal } from "../../components/modal/Modal";
import { T } from "../../components/translation/T";
import { IdDocumentType } from "../../data/models/ContractTypes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  idDocumentType: IdDocumentType;
  takenPhotos: 0 | 1;
}

export const IdentificationUploadModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  idDocumentType,
  takenPhotos,
}) => {
  return (
    <Modal
      showModal={isOpen}
      closeOnOverlayClick={true}
      onClose={() => onClose()}
    >
      {idDocumentType === IdDocumentType.PASSPORT && (
        <div className="id-upload-modal-content">
          <div className="icon"></div>
          <div>
            <h2>
              <T>Passport</T>
            </h2>
            <p>
              <T>
                Take a picture of the plastic page in your passport. It's
                important that the image is clear and well lit so that the
                information is easily readable
              </T>
            </p>
          </div>
        </div>
      )}

      {idDocumentType === IdDocumentType.ID_CARD && takenPhotos === 0 && (
        <div className="id-upload-modal-content">
          <div className="icon"></div>
          <div>
            <h2>
              <T>Front of ID-card</T>
            </h2>
            <p>
              <T>
                Take a picture of the front of your ID-card. It's important that
                the image is clear and well lit so that the information is
                easily readable
              </T>
            </p>
          </div>
        </div>
      )}

      {idDocumentType === IdDocumentType.ID_CARD && takenPhotos === 1 && (
        <div className="id-upload-modal-content">
          <div className="icon"></div>
          <div>
            <h2>
              <T>Back of ID-card</T>
            </h2>
            <p>
              <T>
                Take a picture of the back of your ID-card. It's important that
                the image is clear and well lit so that the information is
                easily readable
              </T>
            </p>
          </div>
        </div>
      )}

      <Button onClick={() => onClose()}>
        <T>Continue</T>
      </Button>
    </Modal>
  );
};
