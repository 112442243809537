import React from "react";
import "./CircleBg.scss";

interface Props {
  children: React.ReactNode;
}

export const CircleBg: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="circle-bg">
      <div className="circle-bg-front">{children}</div>
      <div className="circle-bg-a">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <path d="M 300 8 A 292 292 0 1 1 300 592" className="right-side" />

          <path d="M 300 36 A 264 264 0 1 1 300 564" className="right-side" />

          <path d="M 300 64 A 236 236 0 1 1 300 536" className="right-side" />

          <path d="M 300 92 A 208 208 0 1 1 300 508" className="right-side" />

          <path d="M 300 120 A 180 180 0 1 1 300 480" className="right-side" />

          <path d="M 300 592 A 292 292 0 1 1 300 8" className="left-side" />

          <path d="M 300 564 A 264 264 0 1 1 300 36" className="left-side" />

          <path d="M 300 536 A 236 236 0 1 1 300 64" className="left-side" />

          <path d="M 300 508 A 208 208 0 1 1 300 92" className="left-side" />

          <path d="M 300 480 A 180 180 0 1 1 300 120" className="left-side" />
        </svg>
      </div>
      <div className="circle-bg-b">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <path d="M 300 8 A 292 292 0 1 1 300 592" className="right-side" />

          <path d="M 300 36 A 264 264 0 1 1 300 564" className="right-side" />

          <path d="M 300 64 A 236 236 0 1 1 300 536" className="right-side" />

          <path d="M 300 92 A 208 208 0 1 1 300 508" className="right-side" />

          <path d="M 300 120 A 180 180 0 1 1 300 480" className="right-side" />

          <path d="M 300 592 A 292 292 0 1 1 300 8" className="left-side" />

          <path d="M 300 564 A 264 264 0 1 1 300 36" className="left-side" />

          <path d="M 300 536 A 236 236 0 1 1 300 64" className="left-side" />

          <path d="M 300 508 A 208 208 0 1 1 300 92" className="left-side" />

          <path d="M 300 480 A 180 180 0 1 1 300 120" className="left-side" />
        </svg>
      </div>
    </div>
  );
};
