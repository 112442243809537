import React from "react";
import { ContractLoader } from "../ContractLoader";
import { Page } from "../Page";
import { Inputs } from "./Inputs";
import { MultiForm } from "../../components/form/MultiForm";
import "./DetailsPage.scss";

export const DETAILS_PAGE_URL = "/:linkId/details";

export function DetailsPage() {
  return (
    <Page className="details-page">
      <ContractLoader>
        <MultiForm>
          <Inputs />
        </MultiForm>
      </ContractLoader>
    </Page>
  );
}
