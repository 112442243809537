import React from "react";
import { useRecoilValue } from "recoil";
import { T } from "../components/translation/T";
import { contractState } from "../state/contractState";
import "./SalesContact.scss";

export const SalesContact: React.FunctionComponent = () => {
  const contract = useRecoilValue(contractState);

  if (!contract) {
    return null;
  }

  return (
    <div className="sales-contact">
      <p>
        <T
          id="For any questions or concerns please contact {{name}} at "
          options={{ name: contract.supportContact.name }}
        />{" "}
        <a href={`mailto:${contract.supportContact.email}`}>
          {contract.supportContact.email}
        </a>
      </p>
    </div>
  );
};
