import { FC } from "react";
// import { MdError } from "react-icons/md";
import { Button } from "../interactions/Buttons/Button";
import { Modal } from "../modal/Modal";
import { T, TCondition, TDefault } from "../translation/T";
import { TSwitch } from "../translation/TSwitch";
import "./CameraError.scss";

interface Props {
  error?: string | DOMException;
  retry: () => void;
}

export const CameraError: FC<Props> = ({ error, retry }) => {
  if (typeof error === "string") {
    console.error(error);
  } else if (
    error?.name === "NotFoundError" ||
    error?.name === "DevicesNotFoundError"
  ) {
    // required track is missing
    console.error("Required track is missing");
  } else if (
    error?.name === "NotReadableError" ||
    error?.name === "TrackStartError"
  ) {
    // webcam or mic are already in use
    console.error("Webcam or mic are already in use");
  } else if (
    error?.name === "OverconstrainedError" ||
    error?.name === "ConstraintNotSatisfiedError"
  ) {
    // constraints can not be satisfied by avb. devices
    console.error("Constraints can not be satisfied by available devices");
  } else if (
    error?.name === "NotAllowedError" ||
    error?.name === "PermissionDeniedError"
  ) {
    // permission denied in browser
    console.log("Permission Denied.");
  } else if (error?.name === "TypeError" || error?.name === "TypeError") {
    // empty constraints object
    console.log("Both audio and video are FALSE");
  } else if (error !== undefined) {
    // other errors
    console.error(error);
    console.log("Sorry! Another error occurred.");
  }

  return (
    <div className="camera-error-modal">
      <Modal showModal={error !== undefined} closeOnOverlayClick={false}>
        <div className="camera-error-modal-content">
          <h2>
            {/* todo fix me */}
            {/* <MdError size={32} color={"var(--color-red-heavy)"} /> */}
            <T>Camera access failed</T>
          </h2>
          <p>
            <TSwitch>
              <TCondition
                condition={
                  typeof error === "object" &&
                  (error?.name === "NotReadableError" ||
                    error?.name === "TrackStartError")
                }
              >
                Webcam is already in use
              </TCondition>
              <TCondition
                condition={
                  typeof error === "object" &&
                  (error?.name === "NotAllowedError" ||
                    error?.name === "PermissionDeniedError")
                }
              >
                Camera permission was denied. This feature will not work unless
                you provide access to your devices camera
              </TCondition>
              <TDefault>An error occured while accessing the camera</TDefault>
            </TSwitch>
          </p>
          <Button onClick={retry}>
            <T>Try again</T>
          </Button>
        </div>
      </Modal>
    </div>
  );
};
