import React, { useState, useCallback } from "react";
import { ITEM_HEIGHT, ITEM_X } from "./IndirectOwnership";
import { Plus } from "../../../components/icons/Plus";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Overlay } from "../../../components/overlay/Overlay";
import { OwnershipOverlay } from "./OwnershipOverlay";
import { useLinkId } from "../../../hooks/useLinkId";
import { useRecoilState } from "recoil";
import { contractState } from "../../../state/contractState";

export const IndirectStart: React.FunctionComponent = () => {
  const [contract, setContract] = useRecoilState(contractState);
  const [open, setOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setOpen(false), []);
  const linkId = useLinkId();

  if (!contract) {
    return null;
  }

  // if (!contract.indirectOwnership.length) {
  //   return null;
  // }

  return (
    <div className="indirect-ownership-start">
      <Overlay open={open} onClose={onClose} className="large">
        <OwnershipOverlay
          contract={contract}
          setOpen={setOpen}
          setContract={setContract}
          linkId={linkId}
        />
      </Overlay>
      <svg width="100%" height={ITEM_HEIGHT / 2}>
        <line x1={ITEM_X} x2={ITEM_X} y1="0" y2={ITEM_HEIGHT / 2} />
        <circle className="outer-circle" r="10" cx={ITEM_X} cy={0} />
        <circle className="inner-circle" r="6" cx={ITEM_X} cy={0} />
      </svg>
      <div className="ownership-item-info">
        <div className="truncated">{`${contract.beneficialOwner.firstName} ${contract.beneficialOwner.lastName}`}</div>
      </div>
      <Button
        className="small ownership-item-add"
        action
        onClick={() => setOpen((prev) => !prev)}
      >
        <Plus />
      </Button>
    </div>
  );
};
