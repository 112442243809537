import { Language, UTCDate } from "../../data/models/ContractTypes";
import { API, ServerError } from "../../network/API";
import { cache } from "../../network/Cache";
import { UNAUTHORIZED_PAGE_URL } from "../../pages/error/UnauthorizedPage";

export type TranslationId = number & { isTranslationId: true };

export enum SiteTranslation {
  merchant = "merchant",
  sales = "sales",
}

export interface TranslationText {
  id: TranslationId;
  created: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  language: Language;
  namespace: string;
  key: string;
  value?: string;
  site: SiteTranslation;
}

export interface Count {
  count: number;
}

export const dataTranslation = {
  loadTranslations: (language?: Language, ignoreCache = false) => {
    const lang = language || "";
    return data.get<TranslationText[]>(
      `/api/translations?language=${lang}`,
      ignoreCache
    );
  },

  suggestText: (id: TranslationId, text: string) => {
    return data
      .post<TranslationText[]>(`/api/translations/${id}`, {
        text: text,
      })
      .then(() => {
        cache.delete("/api/translations");
      });
  },

  updateTranslation: (id: TranslationId, text: string) => {
    return data.post(`/api/translations/${id}`, {
      text,
    });
  },

  deleteText: (id: TranslationId) => {
    return data.delete<void>(`/api/translations/${id}`).then(() => {
      cache.delete("/api/translations");
    });
  },

  countMissingTranslations: (language?: Language) => {
    const lang = language || "";
    return data.get<Count>(`/api/translations/missing/count?language=${lang}`);
  },

  addTranslationLabel: ({
    namespace,
    key,
    page,
  }: {
    namespace: string;
    key: string;
    page?: string;
  }) =>
    data.post(`/translation/${namespace}.json`, {
      key,
      page,
    }),
};

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === 401) {
    window.location.href = UNAUTHORIZED_PAGE_URL;
  }
  return Promise.reject(err);
}

const data = {
  get: <T>(url: string, refreshCache?: boolean, options?: RequestInit) => {
    return API.get<T>(url, refreshCache, options).catch(actAndDelagate);
  },

  post: <T>(url: string, body?: any) => {
    return API.post<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any) => {
    return API.delete<T>(url, body).catch(actAndDelagate);
  },
};
