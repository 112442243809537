import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "../../components/link/Link";
import { useLinkId } from "../../hooks/useLinkId";
import { generatePath } from "react-router-dom";
import { ContractLoader } from "../ContractLoader";
import { EID_PAGE_URL } from "../eId/EIdPage";
import { ITSME_PAGE_URL } from "../itsme/ItsmePage";
import { Page } from "../Page";
import { useRecoilValue } from "recoil";
import { contractState } from "../../state/contractState";
import { REQUEST_MOBILE_PAGE_URL } from "../requestMobile/RequestMobilePage";
import { Card } from "../../components/cards/Card";
import { Image } from "../../components/images/Image";
import { showBeId, showItsMe } from "../intro/IntroPage";
import { Country } from "../../data/models/ContractTypes";
import "./IdPage.scss";
import { T } from "../../components/translation/T";

export const ID_PAGE_URL = "/:linkId/id";

function Inner() {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);

  if (!contract) {
    return null;
  }

  const showItsMeOption = showItsMe(contract.country as Country);
  const showEidOption = showBeId(contract.country as Country);

  if (!showItsMeOption && !showEidOption) {
    return (
      <div className="remote-id text-box">
        <Card header={<T>Confirm my identity</T>}>
          <div className="m-top-20">
            <T>
              Due to anti-money laundering regulations we need to ask you to
              provide us with proof of identity.
            </T>
            <br />
            <br />
            <T>
              When you click the button below, you will be asked to take a
              picture of you and a valid goverment id.
            </T>
            <br />
            <br />
            <i>
              <T>
                Please have your smart phone available as this process can only
                be completed on a mobile phone.
              </T>
            </i>
          </div>

          <Link
            className="as-button block m-top-40"
            link={generatePath(REQUEST_MOBILE_PAGE_URL, { linkId })}
          >
            <T>Confirm my identity</T>
          </Link>
        </Card>
      </div>
    );
  }

  if (showItsMeOption && showEidOption) {
    return (
      <div className="remote-id text-box">
        <h1>
          <T>Digital identification</T>
        </h1>
        <T>
          Due to anti-money laundering regulations we need to ask you to provide
          us with proof of identity.
        </T>
        <Card header={<T>Select provider</T>} className="m-top-40">
          <div className="tablet-columns">
            <div>
              <div className="id-provider">
                <div className="id-logo-wrapper">
                  <Image ratio={1} src="/images/itsme.svg" alt="It's Me logo" />
                </div>
                <div className="id-logo-text">
                  <T>Verify identification with</T>{" "}
                  <Link external link="https://www.itsme-id.com/">
                    It's Me
                  </Link>
                </div>
              </div>
              <div className="id-logo-button">
                <Link
                  link={generatePath(ITSME_PAGE_URL, {
                    linkId,
                  })}
                  className="as-button block nav-button"
                >
                  It's Me
                </Link>
              </div>
              <hr className="provider-divider" />
            </div>
            <div>
              <div className="id-provider">
                <div className="id-logo-wrapper">
                  <Image
                    ratio={154.1 / 110.94}
                    src="/images/beId.svg"
                    alt=".beId logo"
                  />
                </div>
                <div className="id-logo-text">
                  <T>Verify identification with</T>{" "}
                  <Link external link="https://eid.belgium.be/">
                    .beId
                  </Link>
                </div>
              </div>
              <div className="id-logo-button">
                <Link
                  link={generatePath(EID_PAGE_URL, {
                    linkId,
                  })}
                  className="as-button block nav-button"
                >
                  .beId
                </Link>
              </div>
            </div>
          </div>
        </Card>

        <div className="id-fallback">
          <Trans>
            If you <u>don't have access</u> to either{" "}
            <Link external link="https://www.itsme-id.com/">
              It's Me
            </Link>{" "}
            or{" "}
            <Link external link="https://eid.belgium.be/">
              .beId
            </Link>{" "}
            you can use photo id method. You will be asked to take a picture of
            yourself and a valid goverment id.
          </Trans>
          <br />
          <br />
          <i>
            <T>
              Please have your smart phone available as this process can only be
              completed on a mobile phone.
            </T>
          </i>{" "}
          <div className="center m-top-20">
            <Link link={generatePath(REQUEST_MOBILE_PAGE_URL, { linkId })}>
              <b>
                <T>Upload photo ID</T>
              </b>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (showItsMeOption) {
    return (
      <div className="remote-id text-box">
        <h1>{t("Digital identification")}</h1>
        <T>
          Due to anti-money laundering regulations we need to ask you to provide
          us with proof of identity.
        </T>
        <Card header={<T>Select provider</T>} className="m-top-40">
          <div>
            <div className="id-provider">
              <div className="id-logo-wrapper">
                <Image ratio={1} src="/images/itsme.svg" alt="It's Me logo" />
              </div>
              <div className="id-logo-text">
                <T>Verify identification with</T>{" "}
                <Link external link="https://www.itsme-id.com/">
                  It's Me
                </Link>
              </div>
            </div>
            <div className="id-logo-button m-top-20">
              <Link
                link={generatePath(ITSME_PAGE_URL, {
                  linkId,
                })}
                className="as-button block nav-button"
              >
                It's Me
              </Link>
            </div>
          </div>
          <hr />
          <div>
            <T>
              Or you can identify yourself by taking a picture of yourself and a
              valid goverment id.
            </T>
            <br />
            <br />
            <i>
              <T>
                Please have your smart phone available as this process can only
                be completed on a mobile phone.
              </T>
            </i>{" "}
            <div className="center m-top-20">
              <Link
                className="as-button block"
                link={generatePath(REQUEST_MOBILE_PAGE_URL, { linkId })}
              >
                <b>
                  <T>Go on</T>
                </b>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="remote-id text-box">
      <h1>{t("Digital identification")}</h1>
      <T>
        Due to anti-money laundering regulations we need to ask you to provide
        us with proof of identity.
      </T>
      <Card header={<T>Select provider</T>} className="m-top-40">
        <div>
          <div className="id-provider">
            <div className="id-logo-wrapper">
              <Image
                ratio={154.1 / 110.94}
                src="/images/beId.svg"
                alt=".beId logo"
              />
            </div>
            <div className="id-logo-text">
              <T>Verify identification with</T>{" "}
              <Link external link="https://eid.belgium.be/">
                .beId
              </Link>
            </div>
          </div>
          <div className="id-logo-button m-top-20">
            <Link
              link={generatePath(EID_PAGE_URL, {
                linkId,
              })}
              className="as-button block nav-button"
            >
              .beId
            </Link>
          </div>
        </div>
        <hr />
        <div>
          <T>
            Or you can identify yourself by taking a picture of yourself and a
            valid goverment id.
          </T>
          <br />
          <br />
          <i>
            <T>
              Please have your smart phone available as this process can only be
              completed on a mobile phone.
            </T>
          </i>{" "}
          <div className="center m-top-20">
            <Link
              className="as-button block"
              link={generatePath(REQUEST_MOBILE_PAGE_URL, { linkId })}
            >
              <b>
                <T>Go on</T>
              </b>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
}

export const IdPage: React.FunctionComponent = () => {
  return (
    <Page className="id-page">
      <ContractLoader>
        <Inner />
      </ContractLoader>
    </Page>
  );
};
