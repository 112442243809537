import React, { ReactNode } from "react";
import cx from "classnames";
import { Logo } from "../components/images/Logo";
import { CircleBg } from "../components/images/CircleBg";
import { LanguageToggle } from "../components/language/LanguageToggle";
import "./Page.scss";
import { Trans } from "react-i18next";

interface Props {
  children: ReactNode;
  className?: string;
  withoutHeader?: boolean;
}

export const Page: React.FunctionComponent<Props> = ({
  children,
  className,
  withoutHeader = false,
}) => {
  return (
    <div className={cx("page", className)}>
      {!withoutHeader && (
        <CircleBg>
          <div className="logo-wrapper">
            <section>
              <article>
                <Logo />
                <div className="small m-top-10">
                  Digital Payments For A Trusted World
                </div>
              </article>
            </section>
          </div>
        </CircleBg>
      )}

      <div className="text-box text-right">
        <div className="m-top-20">
          <LanguageToggle />
        </div>
      </div>

      <section>
        <article>{children}</article>
      </section>
    </div>
  );
};
