import { TEST_URL } from "../components/utils";
import { API, generateUID } from "../network/API";
import {
  Address,
  BeneficialOwner,
  BeneficialOwnerType,
  CompanyDetails,
  Contract,
  Country,
  DocumentId,
  IndirectOwnership,
  Language,
  LinkId,
  SupportContact,
} from "./models/ContractTypes";

export interface OwnerRequest {
  ownership?: number; // 0.00 - 100
  nationality?: string; //Lands-koden
  dateOfBirth?: string; //Ingen validering
  address?: Address;
}

export interface SearchRequest {
  query: string;
  countryFilter: string[]; //Lista av country codes för at filtrera
}

export interface RemoteIdRequest {
  selfie: string;
  passport?: string;
  idFront?: string;
  idBack?: string;
}

export interface LocationSuggestionResult {
  text: string;
}

export function getCountryFromLanguage(lang: Language): Country {
  if (lang === Language.ENGLISH) {
    return Country.UNITED_KINGDOM;
  }

  if (lang === Language.CZECH) {
    return Country.CZECH_REPUBLIC;
  }

  if (lang === Language.GERMAN) {
    return Country.GERMANY;
  }

  return lang.toLocaleUpperCase() as Country;
}

const realdataMerchant = {
  getContract: (linkId: LinkId, skipCache = false) =>
    API.get<Contract>(`/case/${linkId}`, skipCache),

  status: (linkId: LinkId) =>
    API.get<{ complete: boolean }>(`/case/${linkId}/status`, true),

  saveOwner: (linkId: LinkId, owner: OwnerRequest) =>
    API.post(`/case/${linkId}/person`, owner),

  searchAddress: (linkId: LinkId, search: SearchRequest) =>
    API.post<string[]>(`/case/${linkId}/search`, search),

  suggestAddress: (linkId: LinkId, address: string) =>
    API.post<Address>(`/case/${linkId}/search/suggest`, {
      text: address,
    }),

  saveRemoteId: (linkId: LinkId, idImages: RemoteIdRequest) =>
    API.post(`/case/identify/${linkId}/remote-id`, idImages),

  getQrLink: (linkId: LinkId, phoneNumber: string) =>
    API.post(`/case/identify/${linkId}/remote-id/link`, { phoneNumber }),

  deleteUploadedDocument: (linkId: LinkId, documentId: DocumentId) =>
    API.delete(`/case/identify/${linkId}/documents/${documentId}`),

  changeLanguage: (linkId: LinkId, lang: Language) =>
    API.post(`/case/${linkId}?language=${lang}`),

  saveIndirectOwnership: (
    linkId: LinkId,
    indirectOwnership: IndirectOwnership
  ) =>
    API.post<IndirectOwnership>(`/case/${linkId}/indirect`, indirectOwnership),

  saveIndirectOwnershipAddress: (linkId: LinkId, id: string, address: string) =>
    API.post(`/case/${linkId}/indirect/${id}/address`, {
      text: address,
    }),

  deleteIndirectOwnership: (
    linkId: LinkId,
    indirectOwnership: IndirectOwnership
  ) => API.delete(`/case/${linkId}/indirect/${indirectOwnership.id}`),
};

const fakeDataMerchant = {
  getContract: (linkId: LinkId, skipCache = false) => {
    return Promise.resolve({
      country: "BE",
      language: "nl",
      companyDetails: {
        legalName: "Test Company",
        companyId: "123455678",
        address: {
          street: "Demostreet 5",
          city: "Demo City",
          country: "BE",
          postalCode: "12345",
        } as Address,
      } as CompanyDetails,
      beneficialOwner: {
        firstName: "Demo",
        lastName: "Demosurname",
        address: {},
      } as BeneficialOwner,
      beneficialOwnerType: BeneficialOwnerType.OWNERSHIP,
      supportContact: {
        name: "Support Lastname",
        email: "support@example.com",
      } as SupportContact,
      indirectOwnership: [] as IndirectOwnership[],
    } as Contract);
  },

  status: (linkId: LinkId) => {
    return Promise.resolve({ complete: false });
  },

  saveOwner: (linkId: LinkId, owner: OwnerRequest) => Promise.resolve(),

  searchAddress: (linkId: LinkId, search: SearchRequest) =>
    Promise.resolve([
      "Demosteet 5, 12345, Democity, DMO",
      "Testroad 44, 12345, Testcity, DMO",
    ]),

  suggestAddress: (linkId: LinkId, address: string) =>
    Promise.resolve({
      city: "Democity",
      country: "DE",
      postalCode: "12345",
      street: "Demostreet 5",
    } as Address),

  saveRemoteId: (linkId: LinkId, idImages: RemoteIdRequest) =>
    Promise.resolve(),

  getQrLink: (linkId: LinkId, phoneNumber: string) => Promise.resolve(),

  deleteUploadedDocument: (linkId: LinkId, documentId: DocumentId) =>
    Promise.resolve(),

  changeLanguage: (linkId: LinkId, lang: Language) => Promise.resolve(),

  saveIndirectOwnership: (
    linkId: LinkId,
    indirectOwnership: IndirectOwnership
  ) => Promise.resolve({ ...indirectOwnership, id: generateUID() }),

  deleteIndirectOwnership: (
    linkId: LinkId,
    indirectOwnership: IndirectOwnership
  ) => Promise.resolve(),
};

function resolveDataMerchant() {
  if (window.location.pathname === TEST_URL) {
    return fakeDataMerchant;
  }
  return realdataMerchant;
}

export const dataMerchant = resolveDataMerchant();
