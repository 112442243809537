import React, { useCallback, useMemo, useState } from "react";
import { Card } from "../../components/cards/Card";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { contractState } from "../../state/contractState";
import { TextInput } from "../../components/form/TextInput";
import { DateTime } from "luxon";
import { MaxDateValidator } from "../../components/form/validators/MaxDateValidator";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import {
  Address,
  BeneficialOwnerType,
  Language,
} from "../../data/models/ContractTypes";
import { Button } from "../../components/interactions/Buttons/Button";
import { Form } from "../../components/form/Form";
import { dataMerchant } from "../../data/dataMerchant";
import { useLinkId } from "../../hooks/useLinkId";
import { Status } from "../../data/types";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { generatePath, useNavigate } from "react-router-dom";
import { SalesContact } from "../SalesContact";
import { ID_PAGE_URL } from "../id/IdPage";
import { Owner } from "./ownerStructure/Owner";
import { Title } from "./ownerStructure/Title";
import { AddressWithCountry } from "../../components/address/AddressWithCountry";
import { Select } from "../../components/form/Select";
import { getCountryOpts } from "../../components/utils";
import { useMultiForm } from "../../components/form/MultiFormContext";
import { FormName } from "../../App";
import { MinDateValidator } from "../../components/form/validators/MinDateValidator";
import { T } from "../../components/translation/T";
import "./Inputs.scss";

export function calcAge(date: string) {
  const isoDate = DateTime.fromISO(date);
  const years = DateTime.now().diff(isoDate, "years").years;
  return Math.abs(Math.floor(years));
}

export const Inputs: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [contract, setContract] = useRecoilState(contractState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const now = DateTime.now();
  const maxDate = now.toJSDate();
  const maxDateIso = now.toFormat("yyyy-MM-dd");
  const minDate = now.minus({ years: 100 });
  const minDateIso = minDate.toFormat("yyyy-MM-dd");
  const linkId = useLinkId();
  const navigate = useNavigate();
  const multiForm = useMultiForm();

  const onSave = useCallback(() => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    dataMerchant
      .saveOwner(linkId, {
        ownership: contract?.beneficialOwner.ownership,
        dateOfBirth: contract?.beneficialOwner.dateOfBirth,
        address: contract?.beneficialOwner.address,
        nationality: contract?.beneficialOwner.nationality,
      })
      .then(() => {
        navigate(
          generatePath(ID_PAGE_URL, {
            linkId,
          })
        );
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [multiForm, linkId, contract?.beneficialOwner, navigate]);

  const opts = useMemo(() => {
    return getCountryOpts(i18n.language as Language);
  }, [i18n.language]);

  if (!contract) {
    return null;
  }

  return (
    <div className="inputs">
      <div className="m-bottom-40">
        <SalesContact />
      </div>
      <div>
        <Card header={<T>Company</T>}>
          {contract.beneficialOwnerType === BeneficialOwnerType.ROLE ? (
            <Title />
          ) : (
            <Owner />
          )}
        </Card>

        <Card header={t("Owner details")}>
          <div className="m-bottom-10">
            <p>
              <T>Also we need your personal address as well as your age.</T>
            </p>
          </div>

          <Form name={FormName.OWNER_DETAILS}>
            <div className="tablet-columns">
              <div>
                <AddressWithCountry
                  address={{
                    ...contract.beneficialOwner.address,
                    country:
                      contract.beneficialOwner.address.country ||
                      contract.country,
                  }}
                  onCountryChange={(country) => {
                    setContract((prev) => {
                      if (!prev) {
                        return null;
                      }

                      return {
                        ...prev,
                        beneficialOwner: {
                          ...prev.beneficialOwner,
                          address: {
                            street: "",
                            postalCode: "",
                            city: "",
                            country,
                          },
                        },
                      };
                    });
                  }}
                  onChange={(address: Address) => {
                    setContract((prev) => {
                      if (!prev) {
                        return null;
                      }

                      return {
                        ...prev,
                        beneficialOwner: {
                          ...prev.beneficialOwner,
                          address,
                        },
                      };
                    });
                  }}
                />
                <hr />
              </div>
              <div>
                <Select
                  label={<T>Select nationality</T>}
                  value={contract.beneficialOwner.nationality}
                  onChange={(value) => {
                    setContract((prev) => {
                      if (!prev) {
                        return null;
                      }

                      return {
                        ...prev,
                        beneficialOwner: {
                          ...prev.beneficialOwner,
                          nationality: value,
                        },
                      };
                    });
                  }}
                  alternatives={opts}
                  validators={[new RequiredValidator(t("Country is required"))]}
                />

                <TextInput
                  type="date"
                  attributes={{
                    max: maxDateIso,
                    min: minDateIso,
                  }}
                  label={<T>Date of birth</T>}
                  onChange={(value) => {
                    setContract((prev) => {
                      if (!prev) {
                        return null;
                      }

                      return {
                        ...prev,
                        beneficialOwner: {
                          ...prev.beneficialOwner,
                          dateOfBirth: value,
                        },
                      };
                    });
                  }}
                  message={
                    contract.beneficialOwner.dateOfBirth ? (
                      <T
                        id="{{age}} years old"
                        options={{
                          age: calcAge(contract.beneficialOwner.dateOfBirth),
                        }}
                      />
                    ) : null
                  }
                  value={contract.beneficialOwner.dateOfBirth}
                  autocomplete="off"
                  placeholder="1980-01-01"
                  validators={[
                    new RequiredValidator(t("Date of birth is required")),
                    new MinDateValidator(
                      now.minus({ years: 1000 }).toJSDate(),
                      t(
                        "Sorry Highlander, you have to be a mortal to become a customer here"
                      )
                    ),
                    new MinDateValidator(
                      now.minus({ years: 300 }).toJSDate(),
                      t("Sorry, we don't take immortal vampires as customers")
                    ),
                    new MinDateValidator(
                      now.minus({ years: 120 }).toJSDate(),
                      t("Are you sure you are over 120 years old?")
                    ),
                    new MaxDateValidator(
                      now.plus({ years: 100 }).toJSDate(),
                      t(
                        "Sorry, we don't take time travelers, are you sure you are from the future?"
                      )
                    ),
                    new MaxDateValidator(
                      maxDate,
                      t("Well, you are young. Perhaps too young?")
                    ),
                    new MinDateValidator(
                      minDate.toJSDate(),
                      t("Always nice with a senior owner. Perhaps too senior?")
                    ),
                  ]}
                />
              </div>
            </div>
          </Form>
        </Card>

        <AnimateHeight name={status}>
          {status === Status.ERROR ? (
            <ErrorBox relative>
              <T>
                Oh! Something went wrong. We couldn't save the information. Try
                again?
              </T>
            </ErrorBox>
          ) : (
            <div />
          )}
        </AnimateHeight>

        <div className="m-top-40">
          <div className="text-right">
            <Button className="nav-button" type="button" onClick={onSave}>
              <T>Next</T>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
