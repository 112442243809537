import cx from "classnames";
import "./Badge.scss";

interface Props {
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  absolute?: boolean;
  inline?: boolean;
  className?: string;
}

export const Badge: React.FunctionComponent<Props> = ({
  children,
  content,
  absolute,
  className,
  inline = false,
}) => {
  return (
    <div className={cx("badge", className, { absolute, inline })}>
      {content || children}
    </div>
  );
};
