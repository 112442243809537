import countries from "./countries.json";
import { Alternative } from "./interactions/InputTypes";
import { PHONE_COUNTRY_CODES } from "./form/validators/PhoneCountryCodes";
import { nanoid } from "nanoid";
import { Language } from "../data/models/ContractTypes";

export const MISSING_DATE = " - ";

export const TEST_URL = "/TEST";

export function id() {
  return nanoid();
}

export function getCountryOpts(lang: Language, allowEmpty: boolean = false) {
  const regionNames = new (Intl as any).DisplayNames([lang], {
    type: "region",
  });

  const countryList = Object.entries(countries).map(
    (country): Alternative<string | undefined> => ({
      value: country[0],
      text: regionNames.of(country[0]),
      disabled: false,
    })
  );

  if (allowEmpty) {
    countryList.unshift({
      value: undefined,
      text: "None",
      disabled: false,
    });
  } else {
    countryList.unshift({
      value: undefined,
      text: "Country",
      disabled: true,
    });
  }

  return countryList;
}

export const getCountryFromPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return;
  }

  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country: string | undefined = PHONE_COUNTRY_CODES[code];
    if (country) {
      return country;
    }
  }
};
