import { useContext, useCallback, useState, useEffect, useRef } from "react";
import { BaseValidator } from "../validators/BaseValidator";
import { FormContext } from "../FormContext";
import { Status } from "../../../data/types";
import { useDebounceFn } from "../../../hooks/useDebounce";

const isDefined = <T>(item: T | undefined): item is T => {
  return !!item;
};

export enum VALIDATION_STATE {
  UNVALIDATED = "UNVALIDATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export function statusToValidationState(status: Status) {
  if (status === Status.SUCCESS) {
    return VALIDATION_STATE.SUCCESS;
  }
  if (status === Status.PENDING) {
    return VALIDATION_STATE.PENDING;
  }
  if (status === Status.ERROR) {
    return VALIDATION_STATE.FAILED;
  }
  return VALIDATION_STATE.UNVALIDATED;
}

export interface ValidationResponse {
  status: VALIDATION_STATE;
  message?: string;
  /**
   * If hideError is set to true, the errors will not be shown.
   * Can be overridden by forcing validation
   */
  hideError?: boolean;
  hint?: string;
}

export interface ValidationProps {
  validators?: BaseValidator[];
  forceValidation?: boolean;
  validateDisabled?: boolean;
}

export function useValidation<T>(
  value: T | undefined = undefined,
  validators: BaseValidator[] = [],
  forceErrors: boolean
): [VALIDATION_STATE, string[], () => void, Status, string[]] {
  const context = useContext(FormContext);
  const cachedValue = useRef<T | undefined | {}>({});
  const cachedValidators = useRef<BaseValidator[]>([]);
  const [validity, setValidity] = useState(VALIDATION_STATE.UNVALIDATED);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [hints, setHints] = useState<string[]>([]);
  const [hiddenErrors, setHiddenErrors] = useState<boolean>(true);

  const validateCb = useCallback(
    (newValue: T) => {
      Promise.all(validators.map((validator) => validator.validate(newValue)))
        .then((responses) => {
          const isValid = responses.every(
            (response) => response.status === VALIDATION_STATE.SUCCESS
          );

          if (isValid) {
            setValidity(VALIDATION_STATE.SUCCESS);
            setErrorMessages([]);
          } else {
            setValidity(VALIDATION_STATE.FAILED);
            setErrorMessages(
              responses
                .filter(
                  (response) => response.status === VALIDATION_STATE.FAILED
                )
                .map((response) => response.message || "Unknown error")
            );
          }
          setHints(responses.map(({ hint }) => hint).filter(isDefined));
          /**
           * This prevents the showing of validation errors until no
           * validator wants to hide errors
           */
          if (hiddenErrors) {
            setHiddenErrors(responses.some((response) => response.hideError));
          }
        })
        .catch(() => {
          setValidity(VALIDATION_STATE.FAILED);
          setHiddenErrors(false);
          setErrorMessages(["Unknown server error"]);
        });
    },
    [validators, setValidity, hiddenErrors]
  );

  const debounceValidate = useDebounceFn(validateCb, 500);

  const validate = useCallback(
    (newValue: T | undefined) => {
      setErrorMessages([]);
      if (validators.length > 0) {
        setValidity(VALIDATION_STATE.PENDING);
        debounceValidate(newValue);
      } else {
        setValidity(VALIDATION_STATE.SUCCESS);
      }
    },
    [debounceValidate, validators]
  );

  useEffect(() => {
    if (
      cachedValue.current !== value ||
      !areValidatorsEqual(validators, cachedValidators.current)
    ) {
      validate(value);
    }

    cachedValue.current = value;
    cachedValidators.current = validators;
  }, [validate, value, validators]);

  const showErrors =
    validators.length > 0 &&
    (forceErrors || (context && context.forceErrors) || !hiddenErrors);

  const status = !showErrors
    ? Status.DEFAULT
    : (validity === VALIDATION_STATE.SUCCESS &&
        (forceErrors || (context && context.forceErrors))) ||
      (validity === VALIDATION_STATE.SUCCESS && String(value).length > 0)
    ? Status.SUCCESS
    : validity === VALIDATION_STATE.FAILED
    ? Status.ERROR
    : Status.DEFAULT;

  return [
    validators.length === 0 ? VALIDATION_STATE.SUCCESS : validity,
    showErrors ? errorMessages : [],
    () => {
      setHiddenErrors(true);
    },
    status,
    hints,
  ];
}

function areValidatorsEqual(
  newValidators: BaseValidator[],
  oldValidators: BaseValidator[]
) {
  if (newValidators.length !== oldValidators.length) {
    return false;
  }
  const newIds = newValidators.map((newValidator) => newValidator.getId());
  const oldIds = oldValidators.map((oldValidator) => oldValidator.getId());
  return newIds.every((id) => oldIds.includes(id));
}
