import React, { useState, useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { contractState } from "../../../state/contractState";
import { useRecoilState } from "recoil";
import { IndirectOwnership } from "./IndirectOwnership";
import { Overlay } from "../../../components/overlay/Overlay";
import { OwnershipOverlay } from "./OwnershipOverlay";
import { useLinkId } from "../../../hooks/useLinkId";
import { IndirectStart } from "./IndirectStart";
import { IndirectEnd } from "./IndirectEnd";
import { IndirectOwnership as Ownership } from "../../../data/models/ContractTypes";
import "./IndirectOwnerships.scss";
import { T } from "../../../components/translation/T";

export const IndirectOwnerships: React.FunctionComponent = () => {
  const [contract, setContract] = useRecoilState(contractState);
  const [open, setOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setOpen(false), []);
  const linkId = useLinkId();

  // useEffect(() => {
  //   if (!contract) {
  //     return;
  //   }

  //   if (indirect && contract.indirectOwnership.length === 0) {
  //     setOpen(true);
  //   }
  // }, [indirect, contract]);

  const sortedOwnerships = useMemo(() => {
    if (!contract) {
      return [];
    }

    const sortedArray: Ownership[] = [];
    const start = contract.indirectOwnership.find(
      (item) => !item.parentCompanyId
    );

    if (!start) {
      return [];
    }

    sortedArray.push(start);

    let loop = true;
    let parent = start.id;

    function findByParent(parent: string) {
      return contract?.indirectOwnership.find(
        (item) => item.parentCompanyId === parent
      );
    }

    while (loop) {
      const item = findByParent(parent);

      if (item) {
        sortedArray.push(item);
        parent = item.id;
      } else {
        loop = false;
      }
    }

    return sortedArray;
  }, [contract]);

  if (!contract) {
    return null;
  }

  return (
    <div className="indirect-ownership">
      <Overlay open={open} onClose={onClose} className="large">
        <OwnershipOverlay
          linkId={linkId}
          contract={contract}
          setOpen={setOpen}
          setContract={setContract}
        />
      </Overlay>

      {/* <Checkboxes
        label={t("My ownership stake is indirectly owned")}
        onChange={() => setIndirect((prev) => !prev)}
        alternatives={[
          {
            value: "true",
            text: t("Yes"),
          },
        ]}
        values={[indirect.toString()]}
      /> */}

      <div className="m-bottom-40">
        <p>
          <T
            id="If your ownership share is indirectly owned, please add all
            such entities"
          />
        </p>
      </div>

      <AnimateHeight name={`${contract.indirectOwnership.length.toString()}`}>
        <div className="indirect-ownership-companies">
          <IndirectStart />
          {sortedOwnerships.map((item) => (
            <IndirectOwnership
              key={item.id}
              contract={contract}
              indirectOwnership={item}
              setContract={setContract}
              linkId={linkId}
            />
          ))}
          <IndirectEnd contract={contract} />
        </div>
      </AnimateHeight>
    </div>
  );
};
