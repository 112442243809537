import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Card } from "../../components/cards/Card";
import { Image } from "../../components/images/Image";
import { Button } from "../../components/interactions/Buttons/Button";
import { T } from "../../components/translation/T";
import { IdDocumentType } from "../../data/models/ContractTypes";
import { useLinkId } from "../../hooks/useLinkId";
import { Page } from "../Page";
import { UPLOAD_ID_PAGE_URL } from "../uploadId/UploadIdPage";
import "./RequestIdTypePage.scss";

export const REQUEST_ID_TYPE_PAGE_URL = "/:linkId/identification/id-selection";

export const RequestIdTypePage = () => {
  const linkId = useLinkId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const passport = {
    url: "/images/passport-BE.webp",
    aspect: 705 / 1000,
  };
  const id = {
    front: { url: "/images/id-front-BE.png", aspect: 716 / 452 },
    back: { url: "/images/id-back-BE.png", aspect: 560 / 354 },
  };

  return (
    <Page className="identification-selection">
      <div className="text-box">
        <Card
          header={<T>Select identification document</T>}
          className="device-card"
        >
          <div className="device-card">
            <div className="grid">
              <div className="grid-text">
                <T>
                  Continue with a passport. We will need a picture of the hard
                  plastic page that shows your face
                </T>
              </div>

              <div className="id-images">
                <Image
                  alt="passport"
                  src={passport.url}
                  ratio={passport.aspect}
                />
              </div>

              <Button
                block
                onClick={() => {
                  navigate(
                    generatePath(UPLOAD_ID_PAGE_URL, {
                      linkId,
                      idType: IdDocumentType.PASSPORT,
                    })
                  );
                }}
                ghost
              >
                <T>Continue with passport</T>
              </Button>
            </div>
            <hr />
            <br />
            <div>
              <div className="grid">
                <div className="grid-text">
                  <T>
                    Continue with a National identity card. Both sides of the ID
                    card will be required.
                  </T>
                </div>
                <div className="id-images">
                  <Image
                    alt="id front"
                    className="id-front"
                    src={id.front.url}
                    ratio={id.front.aspect}
                  />

                  <Image
                    alt="id back"
                    className="id-back"
                    src={id.back.url}
                    ratio={id.back.aspect}
                  />
                </div>
                <Button
                  block
                  onClick={() => {
                    navigate(
                      generatePath(UPLOAD_ID_PAGE_URL, {
                        linkId,
                        idType: IdDocumentType.ID_CARD,
                      })
                    );
                  }}
                  ghost
                >
                  <T>Continue with ID card</T>
                </Button>
              </div>
            </div>
            <br />
          </div>
        </Card>
      </div>
    </Page>
  );
};
