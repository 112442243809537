import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { RecoilRoot } from "recoil";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { APP_ID } from "./network/API";
import "./index.scss";
import "./ui/typography.scss";

Sentry.init({
  dsn: "https://3c1b82813fc8406783890643f9d397e0@o4504866465185792.ingest.sentry.io/4504876978012160",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV,
});

Sentry.setContext("session", {
  appId: APP_ID,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback={"Loading"}>
    {/* <React.StrictMode> */}
    <RecoilRoot>
      <Router>
        <App />
      </Router>
    </RecoilRoot>
    {/* </React.StrictMode> */}
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
