import { ReactElement, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { FormName } from "../../../App";
import { AddressWithCountry } from "../../../components/address/AddressWithCountry";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { Form } from "../../../components/form/Form";
import { NumberInput } from "../../../components/form/NumberInput";
import { TextInput } from "../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T, TCondition, TDefault } from "../../../components/translation/T";
import { TSwitch } from "../../../components/translation/TSwitch";
import { id } from "../../../components/utils";
import { dataMerchant } from "../../../data/dataMerchant";
import {
  Address,
  Country,
  IndirectOwnership,
  LinkId,
} from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { Contract } from "./../../../data/models/ContractTypes";

interface Props {
  indirectOwnership?: IndirectOwnership;
  contract: Contract;
  setContract: SetterOrUpdater<Contract | null>;
  setOpen: (open: boolean) => void;
  linkId: LinkId;
  parent?: string;
}

interface PartialAddress extends Partial<Address> {}

interface PartialOwnership extends Partial<Omit<IndirectOwnership, "address">> {
  address: PartialAddress;
}

const defaultOwnership: PartialOwnership = {
  name: "",
  parentCompanyId: "",
  parentOwnership: undefined,
  companyRegistrationId: "",
  address: {},
  country: undefined,
};

const MIN_LENGTH = 3;
const MAX_LENGTH = 20;

export function OwnershipOverlay({
  indirectOwnership,
  contract,
  setContract,
  setOpen,
  linkId,
  parent,
}: Props): ReactElement {
  const identifier = useRef<string>(id());
  const { t } = useTranslation();
  const [ownership, setOwnership] = useState<PartialOwnership>({
    ...defaultOwnership,
    ...indirectOwnership,
  });

  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const onCancel = useCallback(() => {
    setStatus(Status.DEFAULT);
    setOpen(false);
  }, [setOpen]);

  const save = useCallback(
    (
      request: IndirectOwnership,
      cb?: (savedOwnership: IndirectOwnership) => void
    ) => {
      dataMerchant
        .saveIndirectOwnership(linkId, request)
        .then((data) => {
          const wasUpdate = !!contract.indirectOwnership.find(
            (item) => item.id === request.id
          );

          setContract((prev) => {
            if (!prev) {
              return null;
            }

            if (wasUpdate) {
              // update
              const updatedOwnerships = prev.indirectOwnership.map((item) => {
                if (item.id === request.id) {
                  return data;
                }

                return item;
              });

              return {
                ...prev,
                indirectOwnership: updatedOwnerships,
              };
            } else {
              // new
              return {
                ...prev,
                indirectOwnership: [...prev.indirectOwnership, data],
              };
            }
          });
          setOpen(false);
          cb && cb(data);
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [linkId, setContract, setOpen, contract]
  );

  const onSave = useCallback(() => {
    setStatus(Status.PENDING);

    if (indirectOwnership) {
      // update
      save(ownership as IndirectOwnership);
      return;
    }

    // new
    let request = {
      ...ownership,
    };

    if (parent) {
      request.parentCompanyId = parent;

      const prevChild = contract.indirectOwnership.find(
        (item) => item.parentCompanyId === parent
      );

      if (prevChild) {
        save(request as IndirectOwnership, (data) => {
          save({ ...prevChild, parentCompanyId: data.id });
        });
      } else {
        save(request as IndirectOwnership);
      }
    } else {
      const prevTop = contract.indirectOwnership.find(
        (item) => !item.parentCompanyId
      );
      save(request as IndirectOwnership, (data) => {
        if (!prevTop) {
          return;
        }

        const copy = {
          ...prevTop,
          parentCompanyId: data.id,
        };

        save(copy as IndirectOwnership);
      });
    }
  }, [indirectOwnership, contract, parent, save, ownership]);

  const onChange = useCallback((val: string, name: string) => {
    setOwnership((prev) => ({
      ...prev,
      [name]: val,
    }));
  }, []);

  return (
    <div className="ownership-overlay">
      <Form
        name={`${FormName.OWNERSHIP}-${identifier.current}`}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }
          onSave();
        }}
      >
        <h4>
          <TSwitch>
            <TCondition condition={indirectOwnership !== undefined}>
              Update indirect ownership
            </TCondition>
            <TDefault>Add new indirect ownership</TDefault>
          </TSwitch>
        </h4>

        <div className="tablet-columns m-top-40">
          <div>
            <TextInput
              label={<T>Company name</T>}
              onChange={onChange}
              name="name"
              value={ownership.name}
              validators={[
                new RequiredValidator(t("Company name is required")),
                new MinLengthValidator(
                  MIN_LENGTH,
                  t("Company name must be at least {{min}} characters", {
                    min: MIN_LENGTH,
                  })
                ),
                new MaxLengthValidator(
                  MAX_LENGTH,
                  t("Company name must be less than {{max}} characters", {
                    max: MAX_LENGTH,
                  })
                ),
              ]}
            />
          </div>
          <div>
            <TextInput
              label={<T>Company registration ID</T>}
              onChange={onChange}
              name="companyRegistrationId"
              value={ownership.companyRegistrationId}
              validators={[
                new RequiredValidator(t("Company registration is required")),
              ]}
            />
          </div>
          <div>
            <NumberInput
              //TODO den här borde vara ägarbolagets stake i bolaget (Tror jag)
              label={
                <T
                  id="{{personName}}'s ownership stake in {{companyName}}"
                  options={{
                    companyName: contract.companyDetails.legalName,
                    personName:
                      contract.beneficialOwner.firstName +
                      " " +
                      contract.beneficialOwner.lastName,
                  }}
                />
              }
              onChange={(value) => {
                setOwnership((prev) => ({
                  ...prev,
                  parentOwnership: value,
                }));
              }}
              name="parentOwnership"
              value={
                ownership.parentOwnership
                  ? Number(ownership.parentOwnership)
                  : undefined
              }
              suffix="%"
              placeholder={t("e.g. 30 %")}
              validators={[
                new RequiredValidator(t("Ownership stake is required")),
                new MaxValidator(100, t("Ownership can not be more than 100%")),
                new MinValidator(0, t("Ownership can not be less than 0%")),
              ]}
            />
          </div>

          <div>
            <AddressWithCountry
              forceListOnTop
              address={{
                street: "",
                city: "",
                postalCode: "",
                country: "" as Country,
                ...ownership.address,
              }}
              onCountryChange={(country) => {
                setOwnership((prev) => ({
                  ...prev,
                  address: {
                    street: "",
                    postalCode: "",
                    city: "",
                    country,
                  },
                }));
              }}
              onChange={(address) => {
                setOwnership((prev) => ({
                  ...prev,
                  address,
                }));
              }}
            />
          </div>
        </div>

        <AnimateHeight name={status}>
          {status === Status.ERROR ? (
            <ErrorBox relative>
              <T>Something went wrong. Try again?</T>
            </ErrorBox>
          ) : (
            <div />
          )}
        </AnimateHeight>

        <div className="m-bottom-30">
          <Button block className="m-top-30" type="submit">
            <T>Save indirect ownership</T>
          </Button>
          <Button ghost block className="m-top-10" onClick={onCancel}>
            <T>Cancel</T>
          </Button>
        </div>
      </Form>
    </div>
  );
}
