import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { FormName } from "../../../App";
import { Form } from "../../../components/form/Form";
import { NumberInput } from "../../../components/form/NumberInput";
import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { T } from "../../../components/translation/T";
import { contractState } from "../../../state/contractState";
import { IndirectOwnerships } from "../indirect/IndirectOwnerships";

export const Owner: React.FunctionComponent = () => {
  const [contract, setContract] = useRecoilState(contractState);
  const { t } = useTranslation();
  const ref = React.useRef<HTMLFormElement>(null);

  if (!contract) {
    return null;
  }

  return (
    <>
      <div className="m-bottom-40">
        <p>
          <T>We need to confirm your ownership stake in</T>{" "}
          <b>{contract.companyDetails.legalName}</b>.<br />
          <T>
            Please enter your total ownership share below and, if any, also add
            all indirect ownerships.
          </T>
        </p>
      </div>

      <div className="tablet-columns">
        <div>
          <Form name={FormName.OWNER_OWNERSHIP} ref={ref}>
            <NumberInput
              scrollToRef={ref}
              onChange={(value) => {
                setContract((prev) => {
                  if (!prev) {
                    return null;
                  }

                  return {
                    ...prev,
                    beneficialOwner: {
                      ...prev.beneficialOwner,
                      ownership: value,
                    },
                  };
                });
              }}
              label={<T>Ownership share (%)</T>}
              name="ownership"
              value={contract.beneficialOwner.ownership}
              placeholder={t("e.g. 30 %")}
              attributes={{ min: 25, max: 100 }}
              validators={[
                new RequiredValidator(t("Ownership share is required")),
                new MinValidator(
                  25,
                  t("Minimum ownership share is {{min}}", { min: 25 })
                ),
                new MaxValidator(
                  100,
                  t("Maximum ownership share is {{max}}", { max: 100 })
                ),
              ]}
              suffix="%"
            />
            <br />
          </Form>
        </div>
        <div>
          <IndirectOwnerships />
        </div>
      </div>
    </>
  );
};
