import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useParams, generatePath, useNavigate } from "react-router-dom";
import { Button } from "../../components/interactions/Buttons/Button";
import { CameraError } from "../../components/cameraError/CameraError";
import { IdDocumentType } from "../../data/models/ContractTypes";
import { IdentificationUploadModal } from "./IdentificationUploadModal";
import { useLinkId } from "../../hooks/useLinkId";
import { useRecoilState } from "recoil";
import { idState } from "../../state/idState";
import { UPLOAD_SELFIE_URL } from "../uploadSelfie/UploadSelfiePage";
import { DONE_WITH_SAVE_PAGE_URL } from "../done/DoneWithIdSavePage";
import "./UploadIdPage.scss";
import { T } from "../../components/translation/T";

export const UPLOAD_ID_PAGE_URL = "/:linkId/identification/id-upload/:idType";

export const UploadIdPage = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [image, setImage] = useState<string>();
  const { idType } = useParams();
  const [showModal, setShowModal] = useState(true);
  const [acceptedImages, setAcceptedImages] = useState<string[]>([]);
  const [error, setError] = useState<string | DOMException>();
  const webcamRef = useRef<Webcam>(null);
  const linkId = useLinkId();
  const [ids, setIds] = useRecoilState(idState);
  const navigate = useNavigate();

  const captureImage = useCallback(() => {
    const screenshot = webcamRef.current?.getScreenshot();
    if (!screenshot) {
      return;
    }
    setImage(screenshot);
  }, []);

  useEffect(() => {
    if (ids?.selfie) {
      return;
    }

    navigate(
      generatePath(UPLOAD_SELFIE_URL, {
        linkId,
      })
    );
  }, [linkId, ids, navigate]);

  if (image) {
    return (
      <div className="document-phone-confirm">
        <img src={image} alt="selfie-preview" className="preview" />
        <div className="button-wrapper">
          <Button ghost onClick={() => setImage(undefined)}>
            <T>No, retake</T>
          </Button>
          <Button
            onClick={() => {
              if (!ids) {
                return;
              }

              if (idType === IdDocumentType.PASSPORT) {
                setIds((prev) => {
                  if (!prev) {
                    return null;
                  }

                  return {
                    ...prev,
                    passport: image,
                  };
                });
                navigate(
                  generatePath(DONE_WITH_SAVE_PAGE_URL, {
                    linkId,
                  })
                );
              } else {
                if (acceptedImages.length === 0) {
                  setShowModal(true);
                  setAcceptedImages([image]);
                  setImage(undefined);
                } else {
                  setIds((prev) => {
                    if (!prev) {
                      return null;
                    }

                    return {
                      ...prev,
                      idFront: acceptedImages[0],
                      idBack: image,
                    };
                  });
                  navigate(
                    generatePath(DONE_WITH_SAVE_PAGE_URL, {
                      linkId,
                    })
                  );
                }
              }
            }}
          >
            <T>Yes, proceed</T>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="document-upload-phone">
      <IdentificationUploadModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        takenPhotos={acceptedImages.length === 0 ? 0 : 1}
        idDocumentType={idType as IdDocumentType}
      />
      <CameraError error={error} retry={() => setError(undefined)} />
      <React.Fragment>
        <div className="video-wrapper">
          {!error && (
            <Webcam
              ref={webcamRef}
              onUserMediaError={(error) => setError(error)}
              videoConstraints={{
                // Only request width, due to an iOS issue
                width: 1280,
                facingMode: "environment",
              }}
              screenshotQuality={1}
              minScreenshotWidth={1280}
              screenshotFormat={"image/jpeg"}
            />
          )}
          <div className="video-document-overlay phone"></div>
        </div>
        <div className="button-wrapper">
          <Button onClick={captureImage}>
            <T>Capture image</T>
          </Button>
        </div>
        <canvas className="video-canvas" ref={canvas} />
      </React.Fragment>
    </div>
  );
};
