import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { generatePath, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CameraError } from "../../components/cameraError/CameraError";
import { Button } from "../../components/interactions/Buttons/Button";
import { T } from "../../components/translation/T";
import { useLinkId } from "../../hooks/useLinkId";
import { idState } from "../../state/idState";
import { REQUEST_ID_TYPE_PAGE_URL } from "../requestIdType/RequestIdTypePage";
import { SelfieUploadModal } from "./SelfieUploadModal";
import "./UploadSelfiePage.scss";

export const UPLOAD_SELFIE_URL = "/:linkId/identification/selfie";

export function UploadSelfiePage() {
  const [image, setImage] = useState<string>();
  const linkId = useLinkId();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const webcamRef = useRef<Webcam>(null);
  const [error, setError] = useState<string | DOMException>();
  const setSelfie = useSetRecoilState(idState);

  const captureImage = useCallback(() => {
    const screenshot = webcamRef.current?.getScreenshot();
    if (!screenshot) {
      return;
    }
    setImage(screenshot);
  }, []);

  if (image) {
    return (
      <div className="selfie-confirm-phone">
        <img src={image} alt="selfie-preview" className="preview" />
        <div className="button-wrapper">
          <Button ghost onClick={() => setImage(undefined)}>
            <T>No, retake</T>
          </Button>
          <Button
            onClick={() => {
              setSelfie({
                selfie: image,
              });
              navigate(generatePath(REQUEST_ID_TYPE_PAGE_URL, { linkId }));
            }}
          >
            <T>Yes, proceed</T>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="selfie-upload-phone">
      <SelfieUploadModal
        isOpen={!error && showModal}
        onClose={() => setShowModal(false)}
      />
      <CameraError error={error} retry={() => setError(undefined)} />
      <React.Fragment>
        <div className="video-wrapper">
          {!error && (
            <Webcam
              ref={webcamRef}
              onUserMediaError={(error) => setError(error)}
              videoConstraints={{
                // Only request width, due to an iOS issue
                width: 1280,
                facingMode: "user",
              }}
              screenshotQuality={1}
              minScreenshotWidth={1280}
              screenshotFormat={"image/jpeg"}
              mirrored
            />
          )}
          <div className="video-face-overlay phone"></div>
        </div>
        <div className="button-wrapper">
          <Button onClick={captureImage}>
            <T>Capture image</T>
          </Button>
        </div>
      </React.Fragment>
    </div>
  );
}
