import { Contract } from "./../data/models/ContractTypes";
import { atom } from "recoil";

export const contractState = atom<Contract | null>({
  key: "contract-state",
  default: null,
});

export function getDecimalSeparator(locale?: string) {
  return (
    Intl.NumberFormat(locale)
      .formatToParts(1.1)
      .find((part) => part.type === "decimal")?.value ?? "."
  );
}
