import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { dataMerchant, getCountryFromLanguage } from "../../data/dataMerchant";
import { ACTIVATED_LANGUAGES } from "../../data/models/ContractTypes";
import { Flag } from "../flags/Flag";
import { useLinkId } from "../../hooks/useLinkId";
import "./LanguageToggle.scss";

export function LanguageToggle() {
  const { i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const linkId = useLinkId();

  return (
    <div className="language-toggle">
      {ACTIVATED_LANGUAGES.map((language) => (
        <button
          className={cx({
            active: activeLanguage === language,
          })}
          key={language}
          onClick={() => {
            i18n.changeLanguage(language);
            dataMerchant
              .changeLanguage(linkId, language)
              .catch((err) => console.error(err));
          }}
        >
          <span>
            <Flag country={getCountryFromLanguage(language)} />
          </span>
        </button>
      ))}
    </div>
  );
}
