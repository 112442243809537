import { Beacon } from "../components/beacon/Beacon";
import { T } from "../components/translation/T";
import { Validity } from "../data/models/ContractTypes";
import "./MissingPage.scss";
import { Page } from "./Page";

export const MISSING_PAGE_URL = "/missing";

export function MissingPage() {
  // Somewhat fugly, we presume that the user has alredy
  // completed the contracted
  return (
    <Page className="missing-page">
      <div className="text-box">
        <div className="all-done-box">
          <Beacon validity={Validity.MISSING} />
          <div>
            <strong>
              <T>This link does not exist</T>
            </strong>
            <p>
              <T>Please check your email or phone for a valid address.</T>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}
