import { ValidationResponse } from "../hooks/useValidation";

export abstract class BaseValidator {
  protected error: string;
  public abstract name: string;

  constructor(error: string) {
    this.error = error;
  }

  public getId() {
    return this.error + this.name;
  }

  abstract validate(value: any): Promise<ValidationResponse>;
}
