import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Beacon } from "../../components/beacon/Beacon";
import { Validity } from "../../data/models/ContractTypes";
import { idState } from "../../state/idState";
import { Page } from "../Page";
import { DoneWithIdSavePage } from "./DoneWithIdSavePage";
import "./DonePage.scss";
import { T } from "../../components/translation/T";

export const DONE_PAGE_URL = "/done";

export function DonePage() {
  const { t } = useTranslation();
  const ids = useRecoilValue(idState);

  if (ids?.selfie) {
    return <DoneWithIdSavePage />;
  }

  return (
    <Page className="done-page">
      <div className="text-box">
        <div className="all-done-box">
          <Beacon validity={Validity.VALID} />
          <div>
            <strong>
              <T>You are done!</T>
            </strong>
            <div>
              <T>Thank you for verifying your identity!</T>
            </div>
          </div>
        </div>
        <p></p>
      </div>
    </Page>
  );
}
