import React from "react";
import { Contract } from "../../../data/models/ContractTypes";
import { ITEM_HEIGHT, ITEM_X } from "./IndirectOwnership";

interface Props {
  contract: Contract;
}

export const IndirectEnd: React.FunctionComponent<Props> = ({ contract }) => {
  // if (!contract.indirectOwnership.length) {
  //   return null;
  // }

  return (
    <div className="indirect-ownership-end">
      <svg width="100%" height={ITEM_HEIGHT}>
        <line x1={ITEM_X} x2={ITEM_X} y1="0" y2={ITEM_HEIGHT / 2} />
        <circle
          className="outer-circle"
          r="10"
          cx={ITEM_X}
          cy={ITEM_HEIGHT / 2}
        />
        <circle
          className="inner-circle"
          r="6"
          cx={ITEM_X}
          cy={ITEM_HEIGHT / 2}
        />
      </svg>
      <div className="ownership-item-end-info">
        <div className="truncated">{contract.companyDetails.legalName}</div>
      </div>
    </div>
  );
};
