import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";
import "./Logo.scss";

interface Props {
  className?: string;
}

export const Logo: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <div className={cx("logo", className)}>
      <div className="worldline-logo">
        <Image
          src="/images/logo-wlx.svg"
          ratio={87.026398 / 8.869053}
          alt="Worldline logo"
        />
      </div>
    </div>
  );
};
