import React from "react";
import { ContractLoader } from "../ContractLoader";
import { Page } from "../Page";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Name } from "../../components/name/Name";
import { contractState } from "../../state/contractState";
import { Link } from "../../components/link/Link";
import { generatePath } from "react-router-dom";
import { DETAILS_PAGE_URL } from "../details/DetailsPage";
import { useLinkId } from "../../hooks/useLinkId";
import { SalesContact } from "../SalesContact";
import { Country } from "../../data/models/ContractTypes";
import "./IntroPage.scss";
import { T } from "../../components/translation/T";

// const ITSME = [Country.BELGIUM, Country.NETHERLANDS];
// const BEID = [Country.BELGIUM];
const ITSME: Country[] = [];
const BEID: Country[] = [];

export function showItsMe(country: Country) {
  return ITSME.includes(country);
}

export function showBeId(country: Country) {
  return BEID.includes(country);
}

export const INTRO_PAGE_URL = "/:linkId";

function Inner() {
  const contract = useRecoilValue(contractState);
  const linkId = useLinkId();

  if (!contract) {
    return null;
  }

  const showItsMeOption = showItsMe(contract.country as Country);
  const showBeIdOption = showBeId(contract.country as Country);

  let elem = null;
  if (showItsMeOption && showBeIdOption) {
    elem = (
      <p>
        <Trans>
          In order to continue using our service we will need a few additional
          details from you and also for you to identify yourself by{" "}
          <Link external link="https://eid.belgium.be/nl">
            eID
          </Link>
          ,{" "}
          <Link external link="https://www.itsme-id.com/">
            Itsme
          </Link>{" "}
          or by providing a photo of you and a valid id card.
        </Trans>
      </p>
    );
  } else if (showBeIdOption) {
    elem = (
      <p>
        <Trans>
          In order to continue using our service we will need a few additional
          details from you and also for you to identify yourself either by{" "}
          <Link external link="https://eid.belgium.be/nl">
            eID
          </Link>{" "}
          or by providing a photo of you and a valid id card.
        </Trans>
      </p>
    );
  } else if (showItsMeOption) {
    elem = (
      <p>
        <Trans>
          In order to continue using our service we will need a few additional
          details from you and also for you to identify yourself either by{" "}
          <Link external link="https://www.itsme-id.com/">
            Itsme
          </Link>{" "}
          or by providing a photo of you and a valid id card.
        </Trans>
      </p>
    );
  } else {
    elem = null;
  }

  return (
    <div className="intro">
      <div className="intro-top text-huge m-bottom-30">
        <T>Hi</T>{" "}
        <b>
          <Name owner={contract.beneficialOwner} />!
        </b>
      </div>
      <p className="text-large">
        <strong>{contract?.companyDetails.legalName}</strong>{" "}
        <T>
          is registered for Worldline payment services. We are, however, missing
          some information about you as an owner.
        </T>
      </p>
      <div className="m-top-30">
        <p>
          <T>
            In order to continue using our service we will need a few additional
            details from you and also for you to provide a photo of you and a
            valid id card.
          </T>
        </p>

        <SalesContact />
      </div>

      <Link
        link={generatePath(DETAILS_PAGE_URL, {
          linkId,
        })}
        className="m-top-40 as-button block"
      >
        <T>Ok! Let's go</T>
      </Link>
    </div>
  );
}

export const IntroPage: React.FunctionComponent = () => {
  return (
    <Page className="intro-page">
      <ContractLoader>
        <Inner />
      </ContractLoader>
    </Page>
  );
};
