import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { API } from "./network/API";
import { Language } from "./data/models/ContractTypes";

const SENT_CACHE = new Set<string>();

export enum TRANSLATION_NAMESPACE {
  ADMIN = "admin",
  OUTREACH = "outreach",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.OUTREACH;

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/case/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: Language.ENGLISH,
    ns: [DEFAULT_TRANSLATION_NAMESPACE],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    missingKeyHandler: (lng, ns, key) => {
      const language = lng[0];

      if (language === "en") {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/case/translation/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: key,
          site: DEFAULT_TRANSLATION_NAMESPACE,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export default i18n;
