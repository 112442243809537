import cx from "classnames";
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import { Dynamic, Status } from "../../../data/types";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Error } from "../../icons/Error";
import { Pending } from "../../icons/Pending";
import { id as generateId } from "../../utils";
import "./Base.scss";
import "./Input.scss";

export interface Props {
  status?: Status;
  className?: string;
  value?: string;
  placeholder?: string;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onChange: (value: string, name: string, ev: React.ChangeEvent<any>) => void;
  onBlur?: (value: string, name: string) => void;
  name?: string;
  message?: string | React.ReactNode;
  autoFocus?: boolean;
  type?: string;
  attributes?: Dynamic;
  list?: string;
  required?: boolean;
  autocomplete?: string;
  id?: string;
  onKeyUp?: (
    keyCode: number,
    ctrlKey: boolean,
    shiftKey: boolean,
    altKey: boolean
  ) => void;
}

export interface StatusMessage {
  status: Status;
  message?: ReactNode | string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      status = Status.DEFAULT,
      className,
      name,
      label = null,
      value = "",
      onChange,
      onBlur,
      message = null,
      hint = null,
      placeholder = "",
      autoFocus,
      type,
      attributes = {},
      list,
      required,
      autocomplete,
      id,
      onKeyUp,
    },
    ref
  ) => {
    //Det här kanske är fugly att dra in externt beroende, men de finns en textsträng här som jag måste komma åt för att översätta..
    //Ett alternativ kanske är att göra det till en icon istället?
    const identifier = useRef<string>(id ?? generateId());
    const prevValue = useRef<string | undefined>(value);
    const [internalStatus, setInternalStatus] = useState<StatusMessage>({
      status: Status.DEFAULT,
    });

    const change = useCallback(
      (ev: ChangeEvent<HTMLInputElement>) => {
        onChange(ev.target.value, name || identifier.current, ev);
        setTimeout(() => {
          setInternalStatus((prevStatus) => ({
            ...prevStatus,
            status: Status.DEFAULT,
          }));
        }, 0);
      },
      [onChange, name]
    );

    const onFocus = useCallback(() => {
      prevValue.current = value;
    }, [value]);

    const blur = useCallback(() => {
      // This is so ugly, but will fix that with new input components
      // If the datepicker is used prevValue will correspond to our
      // latest value
      if (type === "date") {
        onBlur && onBlur(value, name || identifier.current);
        return;
      }

      if (prevValue.current === value) {
        return;
      }

      onBlur && onBlur(value, name || identifier.current);
    }, [onBlur, value, name, type]);

    const keyUp = useCallback(
      (ev: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyUp && onKeyUp(ev.keyCode, ev.ctrlKey, ev.shiftKey, ev.altKey);
      },
      [onKeyUp]
    );

    const props: Dynamic = attributes;

    if (type) {
      props.type = type;
    }

    if (list) {
      props.list = list;
    }

    let consolidatedMessage = message;
    let consolidatedStatus = status;
    if (status === Status.DEFAULT) {
      consolidatedStatus = internalStatus.status;
      consolidatedMessage = internalStatus.message || "";
    }

    return (
      <label
        className={cx("input", "input-text", className, consolidatedStatus)}
        htmlFor={identifier.current}
      >
        <div className="input-label-wrapper">
          <div className="input-label">
            <div className="input-label-tag">
              {label || <div className="invisible">empty</div>}
            </div>
          </div>
          {!value && required && (
            <div className="required-marker text-small">required</div>
          )}
        </div>

        <div className="input-frame">
          <input
            name={name || identifier.current}
            id={identifier.current}
            value={value}
            onChange={change}
            onBlur={blur}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={status === Status.DISABLED}
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            onKeyUp={(e) => keyUp(e)}
            ref={ref}
            {...props}
          />

          <div className="input-status">
            <Checkmark />
            <Disabled />
            <Pending />
            <Error />
          </div>
        </div>

        <div className="input-messages">
          <div className="input-hint">{hint}</div>
          <div className="input-message">{consolidatedMessage}</div>
        </div>
      </label>
    );
  }
);
