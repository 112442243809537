import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { TranslationManagement } from "./components/translation/TranslationManagement";
import { translationStateActiveSelector } from "./components/translation/translationState";
import { TEST_URL } from "./components/utils";
import { DetailsPage, DETAILS_PAGE_URL } from "./pages/details/DetailsPage";
import { DonePage, DONE_PAGE_URL } from "./pages/done/DonePage";
import {
  DoneWithIdSavePage,
  DONE_WITH_SAVE_PAGE_URL,
} from "./pages/done/DoneWithIdSavePage";
import { EIdPage, EID_PAGE_URL } from "./pages/eId/EIdPage";
import { ErrorPage, ERROR_PAGE_URL } from "./pages/error/ErrorPage";
import {
  UnauthorizedPage,
  UNAUTHORIZED_PAGE_URL,
} from "./pages/error/UnauthorizedPage";
import { ID_PAGE_URL, IdPage } from "./pages/id/IdPage";
import { INTRO_PAGE_URL, IntroPage } from "./pages/intro/IntroPage";
import { ItsmePage, ITSME_PAGE_URL } from "./pages/itsme/ItsmePage";
import { MissingPage, MISSING_PAGE_URL } from "./pages/MissingPage";
// import {
//   RemoteIdPage,
//   REMOTE_ID_PAGE_URL,
// } from "./pages/remoteId/RemoteIdPage";
import {
  RequestIdTypePage,
  REQUEST_ID_TYPE_PAGE_URL,
} from "./pages/requestIdType/RequestIdTypePage";
import {
  RequestMobilePage,
  REQUEST_MOBILE_PAGE_URL,
} from "./pages/requestMobile/RequestMobilePage";
import {
  UploadIdPage,
  UPLOAD_ID_PAGE_URL,
} from "./pages/uploadId/UploadIdPage";
import {
  UploadSelfiePage,
  UPLOAD_SELFIE_URL,
} from "./pages/uploadSelfie/UploadSelfiePage";

export enum FormName {
  OWNER_TITLE = "owner-title",
  OWNER_OWNERSHIP = "owner-ownership",
  OWNERSHIP = "ownership",
  OWNER_DETAILS = "owner-details",
}

const isTranslationManagementActive = window.location.pathname === TEST_URL;

export function App() {
  const setTranslationMode = useSetRecoilState(translationStateActiveSelector);

  React.useEffect(() => {
    setTranslationMode(isTranslationManagementActive);
  }, [setTranslationMode]);

  return (
    <div className="app">
      <ErrorBoundary showDialog={false}>
        {isTranslationManagementActive && <TranslationManagement />}
        <Routes>
          <Route path={INTRO_PAGE_URL} element={<IntroPage />} />
          <Route path={DETAILS_PAGE_URL} element={<DetailsPage />} />
          <Route path={ID_PAGE_URL} element={<IdPage />} />
          <Route path={ITSME_PAGE_URL} element={<ItsmePage />} />
          <Route path={EID_PAGE_URL} element={<EIdPage />} />
          {/* <Route path={REMOTE_ID_PAGE_URL} element={<RemoteIdPage />} /> */}
          <Route
            path={REQUEST_MOBILE_PAGE_URL}
            element={<RequestMobilePage />}
          />
          <Route
            path={REQUEST_ID_TYPE_PAGE_URL}
            element={<RequestIdTypePage />}
          />
          <Route path={UPLOAD_ID_PAGE_URL} element={<UploadIdPage />} />
          <Route path={UPLOAD_SELFIE_URL} element={<UploadSelfiePage />} />
          <Route path={MISSING_PAGE_URL} element={<MissingPage />} />
          <Route path={DONE_PAGE_URL} element={<DonePage />} />
          <Route path={ERROR_PAGE_URL} element={<ErrorPage />} />
          <Route path={UNAUTHORIZED_PAGE_URL} element={<UnauthorizedPage />} />
          <Route
            path={DONE_WITH_SAVE_PAGE_URL}
            element={<DoneWithIdSavePage />}
          />
          <Route path="/" element={<MissingPage />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}
