import React from "react";
import cx from "classnames";
import ReactCountryFlag from "react-country-flag";
import { Country } from "../../data/models/ContractTypes";
import "./Flag.scss";

interface Props {
  country: Country | "GB";
  active?: boolean;
}

export const Flag: React.FunctionComponent<Props> = ({
  country,
  active = false,
}) => {
  return (
    <ReactCountryFlag
      className={cx("flag", {
        active,
      })}
      style={{
        width: "auto",
        height: "30px",
      }}
      svg
      countryCode={country}
    />
  );
};
