import React, { useState, useCallback } from "react";
import {
  IndirectOwnership as Ownership,
  LinkId,
} from "../../../data/models/ContractTypes";
import { Overlay } from "../../../components/overlay/Overlay";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Plus } from "../../../components/icons/Plus";
import { OwnershipOverlay } from "./OwnershipOverlay";
import { Contract } from "./../../../data/models/ContractTypes";
import { SetterOrUpdater } from "recoil";
import { Edit } from "../../../components/icons/Edit";
import { Trash } from "../../../components/icons/Trash";
import { dataMerchant } from "../../../data/dataMerchant";
import "./IndirectOwnership.scss";

interface Props {
  indirectOwnership: Ownership;
  contract: Contract;
  setContract: SetterOrUpdater<Contract | null>;
  linkId: LinkId;
}

export const ITEM_HEIGHT = 100;
export const ITEM_X = 20;

export const IndirectOwnership: React.FunctionComponent<Props> = ({
  indirectOwnership,
  contract,
  setContract,
  linkId,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [parent, setParent] = useState<string>();
  const [item, setItem] = useState<Ownership | undefined>(indirectOwnership);
  const onClose = useCallback(() => setOpen(false), []);

  const setOpenExisting = useCallback(() => {
    setItem(indirectOwnership);
    setParent("");
    setOpen(true);
  }, [indirectOwnership]);

  const setOpenNew = useCallback(() => {
    setItem(undefined);
    setParent(indirectOwnership.id);
    setOpen(true);
  }, [indirectOwnership]);

  const onDelete = useCallback(() => {
    const { id, parentCompanyId } = indirectOwnership;
    const child = contract.indirectOwnership.find(
      (item) => item.parentCompanyId === id
    );

    if (child) {
      dataMerchant
        .saveIndirectOwnership(linkId, { ...child, parentCompanyId })
        .then((updatedOwnership) => {
          dataMerchant
            .deleteIndirectOwnership(linkId, indirectOwnership)
            .then(() => {
              setContract((prev) => {
                if (!prev) {
                  return null;
                }

                const updatedOwnerships = contract.indirectOwnership.map(
                  (item) => {
                    if (item.id === updatedOwnership.id) {
                      return updatedOwnership;
                    }

                    return item;
                  }
                );

                return {
                  ...prev,
                  indirectOwnership: updatedOwnerships.filter(
                    (item) => item.id !== id
                  ),
                };
              });
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } else {
      dataMerchant
        .deleteIndirectOwnership(linkId, indirectOwnership)
        .then(() => {
          setContract((prev) => {
            if (!prev) {
              return null;
            }

            return {
              ...prev,
              indirectOwnership: contract.indirectOwnership.filter(
                (item) => item.id !== id
              ),
            };
          });
        })
        .catch((err) => console.error(err));
    }
  }, [linkId, indirectOwnership, setContract, contract]);

  return (
    <div className="indirect-ownership-item">
      <Overlay open={open} onClose={onClose} className="large">
        <OwnershipOverlay
          contract={contract}
          indirectOwnership={item}
          setOpen={setOpen}
          setContract={setContract}
          linkId={linkId}
          parent={parent}
        />
      </Overlay>
      <svg width="100%" height={ITEM_HEIGHT}>
        <line x1={ITEM_X} x2={ITEM_X} y1="0" y2={ITEM_HEIGHT} />
        <circle
          className="outer-circle"
          r="10"
          cx={ITEM_X}
          cy={ITEM_HEIGHT / 2}
        />
        <circle
          className="inner-circle"
          r="6"
          cx={ITEM_X}
          cy={ITEM_HEIGHT / 2}
        />
      </svg>
      <div className="ownership-item-info">
        <div className="ownership-item-info-actions">
          <Button ghost className="small" action onClick={setOpenExisting}>
            <Edit />
          </Button>
          <Button className="small danger-button" action onClick={onDelete}>
            <Trash />
          </Button>
        </div>
        <div className="ownership-item-info-name">
          <div className="truncated">{indirectOwnership.name}</div>
        </div>
      </div>
      <Button className="small ownership-item-add" action onClick={setOpenNew}>
        <Plus />
      </Button>
    </div>
  );
};
