import { LinkId } from "./models/ContractTypes";

export enum Status {
  DEFAULT = "",
  DISABLED = "is-disabled",
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "was-successful",
}

export interface Dynamic {
  [key: string]: any;
}

export interface DynamicOfType<T> {
  [key: string]: T;
}

export interface LinkIdParams {
  linkId: LinkId;
}
