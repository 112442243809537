import { Address } from "../../data/models/ContractTypes";
import { BaseValidator } from "../form/validators/BaseValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";

export const addressFieldValidators: Record<keyof Address, BaseValidator[]> = {
  street: [
    new MaxLengthValidator(42, "Street/no cannot be longer than 42 characters"),
  ],
  postalCode: [
    new MaxLengthValidator(
      10,
      "Postal code cannot be longer than 10 characters"
    ),
  ],
  city: [],
  country: [],
};

export const addressFieldRequiredText: Record<keyof Address, string> = {
  street: "Street/no is required",
  postalCode: "Postal code is required",
  city: "City is required",
  country: "Country is required",
};
