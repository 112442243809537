import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Beacon } from "../../components/beacon/Beacon";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { Button } from "../../components/interactions/Buttons/Button";
import { Spinner } from "../../components/spinner/Spinner";
import { T } from "../../components/translation/T";
import { dataMerchant, RemoteIdRequest } from "../../data/dataMerchant";
import { LinkId, Validity } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { useLinkId } from "../../hooks/useLinkId";
import { idState } from "../../state/idState";
import { Page } from "../Page";
import "./DoneWithIdSavePage.scss";

export const DONE_WITH_SAVE_PAGE_URL = "/:linkId/done";

const MIN_TIMER = 2001;

export function DoneWithIdSavePage() {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const ids = useRecoilValue(idState);
  const linkId = useLinkId();
  const hasFetched = useRef<boolean>(false); // Remove? https://blog.bitsrc.io/react-v18-0-useeffect-bug-why-do-effects-run-twice-39babecede93

  const save = useCallback((request: RemoteIdRequest, linkId: LinkId) => {
    if (hasFetched.current) {
      return;
    }

    hasFetched.current = true;

    setStatus(Status.PENDING);
    const endTime = new Date().getTime() + MIN_TIMER;

    dataMerchant
      .saveRemoteId(linkId, request)
      .then(() => {
        const timer = Math.max(endTime - new Date().getTime(), 0);
        setTimeout(() => {
          setStatus(Status.SUCCESS);
        }, timer);
      })
      .catch((err) => {
        const timer = Math.max(endTime - new Date().getTime(), 0);
        setTimeout(() => {
          setStatus(Status.ERROR);
        }, timer);
      });
  }, []);

  useEffect(() => {
    if (!ids) {
      setTimeout(() => {
        setStatus(Status.SUCCESS);
      }, 1500);
      return;
    }

    save(ids, linkId);
  }, [ids, save, linkId]);

  let elem = null;

  if (status === Status.SUCCESS) {
    elem = (
      <div className="all-done-box">
        <Beacon validity={Validity.VALID} />
        <div>
          <strong>
            <T>You are done!</T>
          </strong>
          <div>
            <T>Thank you for verifying your identity!</T>
          </div>
        </div>
      </div>
    );
  } else if (status === Status.ERROR) {
    elem = (
      <>
        <ErrorBox relative>
          <T>Something went wrong. We couldn't save the information.</T>
        </ErrorBox>
        <Button
          className="m-top-20"
          block
          onClick={() => {
            hasFetched.current = false;
            ids && save(ids, linkId);
          }}
        >
          <T>Try again</T>
        </Button>
      </>
    );
  } else if (status === Status.PENDING) {
    elem = <Spinner text={<T>Saving your information</T>} />;
  }

  return (
    <Page className="done-page">
      <div className="text-box">
        <AnimateHeight name={status}>
          <div>{elem}</div>
        </AnimateHeight>
      </div>
    </Page>
  );
}
