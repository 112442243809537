import React from "react";
import { BeneficialOwner } from "../../data/models/ContractTypes";

interface Props {
  owner: BeneficialOwner;
}

export const Name: React.FunctionComponent<Props> = ({ owner }) => {
  return (
    <span>
      {owner.firstName} {owner.lastName}
    </span>
  );
};
