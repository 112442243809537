import { RefObject, useCallback, useMemo, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import {
  Address,
  Country,
  Language,
  Validity,
} from "../../data/models/ContractTypes";
import {
  AddressLocationChange,
  AddressSearch,
} from "../addressSearch/AddressSearch";
import { Beacon } from "../beacon/Beacon";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Location } from "../icons/Location";
import { Link } from "../link/Link";
import { AddressPopover } from "./AddressPopover";
import {
  addressFieldRequiredText,
  addressFieldValidators,
} from "./addressUtils";
import "./AddressWithSearch.scss";
import { T } from "../translation/T";

export function isValidAddress(address: Address): boolean {
  return !!(address.street && address.city && address.country);
}

interface SharedProps {
  address: Address;
  onChange: (address: Address) => void;
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  country?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  forceListOnTop?: boolean;
}

interface PropsWithoutCountryCode extends SharedProps {
  addressRequiredFields?: Record<keyof Omit<Address, "country">, boolean>;
  showCountryCode?: false;
}

interface PropsWithCountryCode extends SharedProps {
  addressRequiredFields: Record<keyof Address, boolean>;
  showCountryCode: true;
}

type Props = PropsWithoutCountryCode | PropsWithCountryCode;

export const AddressWithSearch = ({
  address,
  onChange,
  country,
  setSearchValue,
  searchValue,
  addressRequiredFields = {
    street: false,
    postalCode: false,
    city: false,
    country: false,
  },
  forceListOnTop = false,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();

  const secondAddressRow = useMemo(() => {
    return [address.postalCode, address.city].filter(Boolean).join(", ");
  }, [address]);

  const onAddressSelect = useCallback(
    (location: AddressLocationChange) => {
      onChange({
        ...address,
        ...location,
        country: location.country as Country,
      });
    },
    [onChange, address]
  );

  const googleMapsLink = useMemo(() => {
    if (
      !address.street ||
      !address.postalCode ||
      !address.city ||
      !address.country
    ) {
      return;
    }

    const addressString = [
      address.street,
      address.postalCode,
      address.city,
      address.country || Country.BELGIUM,
    ].join(", ");

    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      addressString
    )}`;
  }, [address]);

  return (
    <div
      className={cx("address-with-search", {
        "has-valid-address": isValidAddress(address),
      })}
      ref={ref}
    >
      <div className="address-search-wrapper">
        <div className="search">
          <AddressSearch
            forceListOnTop={forceListOnTop}
            onChange={onAddressSelect}
            country={country}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <AddressPopover
          language={i18n.language as Language}
          address={address}
          onChange={onAddressSelect}
          addressRequiredFields={addressRequiredFields}
          {...props}
        />
      </div>
      <div className="address-row">
        <Beacon validity={Validity.DEFAULT} icon={<Location />} />

        <ul className="short-info text-small">
          <li>
            {address.street ||
              (secondAddressRow ? "-" : <T>Please enter an address</T>)}
          </li>
          <li className="short-info-address">
            {secondAddressRow || (address.street ? "-" : "")}
          </li>
        </ul>

        {googleMapsLink && (
          <Link link={googleMapsLink} external className="small">
            <T>View in maps</T>
          </Link>
        )}
      </div>
      {Object.entries(addressRequiredFields).map(([key, required]) => {
        if (!required) {
          return null;
        }
        return (
          <HiddenInput
            key={key}
            name={key}
            value={address[key as keyof Address]}
            scrollToRef={ref}
            validators={[
              ...addressFieldValidators[key as keyof Address],
              ...(required
                ? [
                    new RequiredValidator(
                      addressFieldRequiredText[key as keyof Address]
                    ),
                  ]
                : []),
            ]}
            label={key}
          />
        );
      })}
    </div>
  );
};
