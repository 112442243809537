import React from "react";
import { ContractLoader } from "../ContractLoader";
import { Page } from "../Page";
import { useTranslation } from "react-i18next";
import { useLinkId } from "../../hooks/useLinkId";
import { Link } from "../../components/link/Link";
import { INTRO_PAGE_URL } from "../intro/IntroPage";
import { generatePath } from "react-router-dom";
import "./EIdPage.scss";

export const EID_PAGE_URL = "/:linkId/e-id";

export const EIdPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const linkId = useLinkId();

  return (
    <Page className="eid-page">
      <ContractLoader>
        <div className="eid">{t("EId")}</div>

        <Link
          link={generatePath(INTRO_PAGE_URL, {
            linkId,
          })}
          className="m-top-40 as-button block"
        >
          {t("Ok! Let's go")}
        </Link>
      </ContractLoader>
    </Page>
  );
};
