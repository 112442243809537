import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/interactions/Buttons/Button";
import { Modal } from "../../components/modal/Modal";
import { T } from "../../components/translation/T";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SelfieUploadModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      showModal={isOpen}
      closeOnOverlayClick={true}
      onClose={() => onClose()}
    >
      <div className="id-upload-modal-content">
        <div className="icon"></div>
        <div>
          <h2>
            <T>Selfie</T>
          </h2>
          <p>
            <T>
              To verify your identify we need a selfie of you. It's important
              that you're in a well lit room and that the picture is clear and
              in focus.
            </T>
          </p>
        </div>
      </div>
      <Button onClick={() => onClose()}>
        <T>Continue</T>
      </Button>
    </Modal>
  );
};
