import { getDecimalSeparator } from "../../../state/contractState";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MinValidator extends BaseValidator {
  name = "MinValidator";
  private min: number;
  private separator: string;

  constructor(min: number, error: string, locale?: string) {
    super(error);
    this.min = min;
    this.separator = getDecimalSeparator(locale);
  }

  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const val =
        typeof value === "string" ? value.replace(this.separator, ".") : value;
      const num = Number(val);

      if (!value || isNaN(num) || num >= this.min) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
