import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLinkId } from "../../hooks/useLinkId";
import { Status } from "../../data/types";
import { LinkId } from "../../data/models/ContractTypes";
import "./ItsmePage.scss";

export const ITSME_PAGE_URL = "/:linkId/its-me";

function initPolling(linkId: LinkId): Promise<{ clientToken: string }> {
  return new Promise<{ clientToken: string }>((resolve) => {
    setTimeout(() => resolve({ clientToken: "fucker" }), 500);
  });
}

function getSDK() {
  setTimeout(() => Promise.resolve, 800);
}

// function killSession(linkId: LinkId): Promise<void> {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(), 500);
//   });
// }

export const ItsmePage: React.FunctionComponent = () => {
  const [, /** status */ setStatus] = useState<Status>(Status.DEFAULT);
  const [clientToken, setClientToken] = useState<string>();
  const linkId = useLinkId();
  const timer = useRef<number>();
  // const isFirstPoll = useRef<boolean>(true);
  // const noOfRetries = useRef<number>(0);
  // const { t } = useTranslation();

  useEffect(() => {
    const currentTimer = timer.current;
    return () => {
      clearTimeout(currentTimer);
    };
  }, []);

  // const deleteSession = useCallback(
  //   (cb?: () => void) => {
  //     killSession(linkId)
  //       .then(() => {
  //         setStatus(Status.DEFAULT);
  //         setClientToken(undefined);
  //         cb && cb();
  //       })
  //       .catch(() => {
  //         setStatus(Status.DEFAULT);
  //         setClientToken(undefined);
  //         cb && cb();
  //       });
  //   },
  //   [linkId]
  // );

  const requestToken = useCallback((linkId: LinkId) => {
    Promise.all([initPolling(linkId), getSDK()])
      .then((response) => {
        const { clientToken: token } = response[0];
        setClientToken(token);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!linkId) {
      return;
    }
    setStatus(Status.PENDING);
    setClientToken(undefined);
    requestToken(linkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);

  useEffect(() => {
    console.log("clientToken updated", clientToken);
  }, [clientToken]);

  return null;

  // return (
  //   <Page className="its-me-page">
  //     <ContractLoader>
  //       <div className="its-me">{t("Its me")}</div>

  //       <Link
  //         link={generatePath(INTRO_PAGE_URL, {
  //           linkId,
  //         })}
  //         className="m-top-40 as-button block"
  //       >
  //         {t("Ok! Let's go")}
  //       </Link>
  //     </ContractLoader>
  //   </Page>
  // );
};
