import { useCallback, useEffect, useRef, useState } from "react";
import { dataMerchant } from "../data/dataMerchant";
import { LinkId } from "../data/models/ContractTypes";
import { ServerError } from "../network/API";

const INTERVAL = 4000;

export const useIsDonePoll = (id: LinkId) => {
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const timeout = useRef<number>();
  const mountedRef = useRef(false);

  const run = useCallback(async () => {
    dataMerchant
      .status(id)
      .then(({ complete }) => {
        if (complete) {
          setIsDone(true);
        }
      })
      .catch((err: ServerError<any>) => {
        // TODO: perhaps we could check the error here for timeout / network request failed
        // and if so continue polling
        if (err.status) {
          setIsError(true);
        }
      })
      .finally(() => {
        if (mountedRef.current) {
          timeout.current = window.setTimeout(run, INTERVAL);
        }
      });
  }, [id]);

  useEffect(() => {
    if (isDone) return;

    mountedRef.current = true;
    run();

    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [isDone, run]);

  return { isDone, isError };
};
