import React, { useCallback, useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import MobileDetect from "mobile-detect";
import { generatePath, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Card } from "../../components/cards/Card";
import { Button } from "../../components/interactions/Buttons/Button";
import { Page } from "../Page";
import { useLinkId } from "../../hooks/useLinkId";
import { ID_PAGE_URL } from "../id/IdPage";
import { TextInput } from "../../components/form/TextInput";
import { useTranslation } from "react-i18next";
import { dataMerchant } from "../../data/dataMerchant";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Status } from "../../data/types";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { MinLengthValidator } from "../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { PhoneCountryCodeValidator } from "../../components/form/validators/PhoneCountryCodeValidator";
import { Form } from "../../components/form/Form";
import { REQUEST_ID_TYPE_PAGE_URL } from "../requestIdType/RequestIdTypePage";
import { useRecoilValue } from "recoil";
import { idState } from "../../state/idState";
import { UPLOAD_SELFIE_URL } from "../uploadSelfie/UploadSelfiePage";
import { Country } from "../../data/models/ContractTypes";
import { contractState } from "../../state/contractState";
import { useIsDonePoll } from "../../hooks/useIsDonePoll";
import { DONE_PAGE_URL } from "../done/DonePage";
import { ERROR_PAGE_URL } from "../error/ErrorPage";
import { T } from "../../components/translation/T";
import { hasRealErrors } from "../../components/form/FormHelpers";
import "./RequestMobilePage.scss";

function getCountryPrefix(country?: Country): string {
  if (!country) {
    return "+44";
  }

  switch (country) {
    case Country.FRANCE:
      return "+33";
    case Country.GERMANY:
      return "+49";
    case Country.BELGIUM:
      return "+32";
    case Country.NETHERLANDS:
      return "+31";
    case Country.LUXEMBURG:
      return "+352";
    case Country.CZECH_REPUBLIC:
      return "+420";
    case Country.SLOVAKIA:
      return "+421";
    default:
      return "+44";
  }
}

export const REQUEST_MOBILE_PAGE_URL = "/:linkId/request-mobile";

export const RequestMobilePage = () => {
  const { t } = useTranslation();
  const contract = useRecoilValue(contractState);
  const [phoneNumber, setPhoneNumber] = useState("");
  const linkId = useLinkId();
  const navigate = useNavigate();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const ids = useRecoilValue(idState);
  const { isDone, isError } = useIsDonePoll(linkId);

  const device = useMemo(
    () => new MobileDetect(window.navigator.userAgent),
    []
  );

  const onSave = useCallback(() => {
    dataMerchant
      .getQrLink(linkId, phoneNumber)
      .then(() => setStatus(Status.SUCCESS))
      .catch((err) => setStatus(Status.ERROR));
  }, [linkId, phoneNumber]);

  useEffect(() => {
    if (device.isPhoneSized()) {
      if (!ids?.selfie) {
        navigate(generatePath(UPLOAD_SELFIE_URL, { linkId }));
      } else {
        navigate(generatePath(REQUEST_ID_TYPE_PAGE_URL, { linkId }));
      }
    }
  }, [device, ids, linkId, navigate]);

  useEffect(() => {
    if (isDone) {
      navigate(DONE_PAGE_URL);
    }
  }, [isDone, navigate]);

  useEffect(() => {
    if (isError) {
      navigate(ERROR_PAGE_URL);
    }
  }, [isError, navigate]);

  if (device.isPhoneSized()) {
    return null;
  }

  const phoneCountryCode = getCountryPrefix(
    contract?.beneficialOwner.address.country as Country
  );
  return (
    <Page className="request-mobile-page">
      <Form
        onSubmit={(event, form) => {
          setStatus(Status.DEFAULT);
          if (form.isInvalid) {
            return;
          }

          const realErrors = hasRealErrors(form);

          if (realErrors) {
            return;
          }

          onSave();
        }}
      >
        <div className="request-mobile-wrapper">
          <div className="text-box">
            <Card header={<T>Continue on mobile</T>} className="device-card ">
              <div className="tablet-columns">
                <div>
                  <p>
                    <T>
                      The document upload requires you to use your phone. Scan
                      the QR code to continue on your phone.
                    </T>
                  </p>
                </div>

                <div className="qr-code-wrapper center">
                  <QRCode value={window.location.href} size={128} />
                </div>
              </div>

              <hr />

              <p>
                <T>Or click the link below to send a link to your phone.</T>
              </p>
              <TextInput
                label={<T>Phone number</T>}
                placeholder={`${phoneCountryCode}123456789`}
                value={phoneNumber}
                onChange={setPhoneNumber}
                disabled={Status.SUCCESS === status}
                validators={[
                  new RequiredValidator(t("Phone number is requried")),
                  new PhoneCountryCodeValidator(
                    t("Phone number must start with a country code. Ex. +46")
                  ),
                  new MinLengthValidator(
                    8,
                    "The phone number has to be at least 8 characters"
                  ),
                ]}
              />

              <AnimateHeight name={status}>
                <div>
                  {status === Status.DEFAULT && <div />}

                  {status === Status.ERROR && (
                    <ErrorBox relative>
                      <T>Failed to send link to phone</T>
                    </ErrorBox>
                  )}

                  {status === Status.SUCCESS && (
                    <SuccessBox relative>
                      <T>Sent link to phone</T>
                    </SuccessBox>
                  )}
                </div>
              </AnimateHeight>

              <Button
                type="submit"
                className="m-top-40"
                block
                status={
                  status === Status.SUCCESS ? Status.DISABLED : Status.DEFAULT
                }
              >
                <T>Text me a link</T>
              </Button>
            </Card>
          </div>
          <div className="center">
            <Link to={generatePath(ID_PAGE_URL, { linkId })}>
              <T>Go back</T>
            </Link>
          </div>
        </div>
      </Form>
    </Page>
  );
};
