import { Beacon } from "../../components/beacon/Beacon";
import { T } from "../../components/translation/T";
import { Validity } from "../../data/models/ContractTypes";
import { Page } from "../Page";
import "./ErrorPage.scss";

export const ERROR_PAGE_URL = "/error";

export function ErrorPage() {
  return (
    <Page className="error-page">
      <div className="text-box">
        <div className="error-box">
          <Beacon validity={Validity.MISSING} />
          <div>
            <strong>
              <T>Something went wrong</T>
            </strong>
            <div>
              <T>This wasn't supposed to happen... please try again.</T>
            </div>
          </div>
        </div>
        <p></p>
      </div>
    </Page>
  );
}
