import cx from "classnames";
import React, { RefObject, useCallback, useMemo } from "react";
import { Address, Language } from "../../data/models/ContractTypes";
import { Select } from "../form/Select";
import { TextInput } from "../form/TextInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { getCountryOpts } from "../utils";
import {
  addressFieldRequiredText,
  addressFieldValidators,
} from "./addressUtils";

interface Props {
  address: Address;
  onChange: (address: Address) => void;
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  addressRequiredFields:
    | Record<keyof Address, boolean>
    | Record<keyof Omit<Address, "country">, boolean>;
  showCountryCode?: boolean;
  language: Language;
}

export const AddressForm: React.FunctionComponent<Props> = ({
  address,
  onChange,
  disabled = false,
  scrollToRef,
  showCountryCode,
  addressRequiredFields,
  language,
}) => {
  const onUpdate = useCallback(
    (value: any, name: string) => {
      const update = { ...address };
      if (name === "country") {
        update[name as keyof Address] = value.value;
      } else {
        update[name as keyof Address] = value;
      }
      onChange(update);
    },
    [address, onChange]
  );

  const countryOpts = useMemo(() => {
    return showCountryCode ? getCountryOpts(language) : [];
  }, [showCountryCode, language]);

  return (
    <>
      <div className={cx("address-input")}>
        <TextInput
          onChange={onUpdate}
          name="street"
          label="Street/no"
          value={address?.street}
          validators={[
            ...(addressRequiredFields.street
              ? [new RequiredValidator(addressFieldRequiredText["street"])]
              : []),
            ...addressFieldValidators["street"],
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("address-input")}>
        <TextInput
          onChange={onUpdate}
          name="postalCode"
          label="Postal code"
          value={address?.postalCode}
          validators={[
            ...(addressRequiredFields.postalCode
              ? [new RequiredValidator(addressFieldRequiredText["postalCode"])]
              : []),
            ...addressFieldValidators["postalCode"],
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("address-input")}>
        <TextInput
          onChange={onUpdate}
          name="city"
          label="City"
          value={address?.city}
          validators={[
            ...(addressRequiredFields.city
              ? [new RequiredValidator(addressFieldRequiredText["city"])]
              : []),
            ...addressFieldValidators["city"],
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>

      <div className={cx("address-input")}>
        <Select
          onChange={onUpdate}
          name="country"
          label="Country"
          alternatives={countryOpts}
          value={address?.country}
          validators={[
            ...("country" in addressRequiredFields &&
            addressRequiredFields.country
              ? [new RequiredValidator(addressFieldRequiredText["country"])]
              : []),
            ...addressFieldValidators["country"],
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
    </>
  );
};
