import React, { ReactNode } from "react";
import cx from "classnames";
import "./Card.scss";

interface Props {
  className?: string;
  children: ReactNode;
  header?: ReactNode;
  withBackground?: boolean;
}

export const Card: React.FunctionComponent<Props> = ({
  children,
  className,
  header = null,
  withBackground = true,
}) => {
  return (
    <div
      className={cx("card", className, {
        "has-header": !!header,
      })}
    >
      <div className="card-header">
        <h4 className="truncate">{header}</h4>
      </div>

      <div className="card-body">{children}</div>
    </div>
  );
};
