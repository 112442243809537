import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useRecoilState } from "recoil";
import { NumberInput } from "../../../components/form/NumberInput";
import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { contractState } from "../../../state/contractState";
import i18n from "../../../i18n";
import { Form } from "../../../components/form/Form";
import { FormName } from "../../../App";
import { T } from "../../../components/translation/T";

export const Title: React.FunctionComponent = () => {
  const [contract, setContract] = useRecoilState(contractState);
  const { t } = useTranslation();
  const ref = React.useRef<HTMLFormElement>(null);

  if (!contract) {
    return null;
  }

  if (!contract.beneficialOwner.title) {
    return null;
  }

  let title = i18n.exists(contract.beneficialOwner.title)
    ? t(contract.beneficialOwner.title)
    : contract.beneficialOwner.title;

  return (
    <>
      <div className="m-bottom-40">
        <p>
          <T
            id="As {{ title }} of {{legalName}} we need to know if you are also have
            ownership shares in the company. Please enter your total ownership
            share."
            options={{
              title: title,
              legalName: contract.companyDetails.legalName,
            }}
          />
        </p>
      </div>

      <Form name={FormName.OWNER_TITLE} ref={ref}>
        <div className="tablet-columns">
          <div>
            <NumberInput
              onChange={(value) => {
                setContract((prev) => {
                  if (!prev) {
                    return null;
                  }

                  return {
                    ...prev,
                    beneficialOwner: {
                      ...prev.beneficialOwner,
                      ownership: value,
                    },
                  };
                });
              }}
              label={<T>Ownership share (%)</T>}
              name="ownership"
              value={contract.beneficialOwner.ownership}
              placeholder={t("e.g 30 %")}
              attributes={{ min: 0, max: 100 }}
              validators={[
                new RequiredValidator(t("Ownership share is required")),
                new MinValidator(
                  0,
                  t("Minimum ownership share is {{min}}", { min: 0 })
                ),
                new MaxValidator(
                  100,
                  t("Maximum ownership share is {{max}}", { max: 100 })
                ),
              ]}
              suffix="%"
            />
            <br />
          </div>
        </div>
      </Form>
    </>
  );
};
