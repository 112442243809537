import { Button } from "../../components/interactions/Buttons/Button";
import { TEST_URL } from "../../components/utils";
import { Page } from "../Page";

export const UNAUTHORIZED_PAGE_URL = "/unauthorized";

export function UnauthorizedPage() {
  return (
    <Page className="unauthorized-page">
      <div className="text-box">
        <h4>Unauthorized</h4>
        <div>
          Please sign into the outreach admin portal as a manager and then click
          the button below to continue.
        </div>
        <Button
          onClick={() => {
            window.location.href = TEST_URL;
          }}
          className="m-top-20"
        >
          Done, let's go!
        </Button>
      </div>
    </Page>
  );
}
