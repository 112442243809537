import React, { useMemo, useState } from "react";
import { Select } from "../form/Select";
import { useTranslation } from "react-i18next";
import { Address, Country, Language } from "../../data/models/ContractTypes";
import { AnimateHeight } from "../animate/AnimateHeight";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { getCountryOpts } from "../utils";
import { AddressWithSearch } from "./AddressWithSearch";
import "./AddressWithCountry.scss";
import { T } from "../translation/T";

interface Props {
  onCountryChange: (country: Country) => void;
  onChange: (address: Address) => void;
  address: Address;
  forceListOnTop?: boolean;
}

export const AddressWithCountry: React.FunctionComponent<Props> = ({
  onCountryChange,
  address,
  onChange,
  forceListOnTop = false,
}) => {
  const { t, i18n } = useTranslation();
  const [showAddressField, setShowAddressField] = useState<boolean>(
    !!address.country
  );
  const [country, setCountry] = useState<string>(address.country || "");
  const [searchValue, setSearchValue] = useState<string>("");

  const opts = useMemo(() => {
    return getCountryOpts(i18n.language as Language);
  }, [i18n.language]);

  return (
    <>
      <Select
        label={<T>Select country of residence</T>}
        value={country}
        onChange={(value) => {
          setSearchValue("");
          setShowAddressField(true);
          setCountry(value || "");
          onCountryChange && onCountryChange(value as Country);
        }}
        alternatives={opts}
        validators={[new RequiredValidator(t("Country is required"))]}
      />

      <AnimateHeight name={showAddressField.toString()}>
        {showAddressField ? (
          <AddressWithSearch
            forceListOnTop={forceListOnTop}
            address={address}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            country={country}
            onChange={onChange}
            addressRequiredFields={{
              street: true,
              postalCode: true,
              city: true,
              country: true,
            }}
          />
        ) : (
          <div />
        )}
      </AnimateHeight>
    </>
  );
};
