import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import i18n from "../i18n";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Retry } from "../components/retry/Retry";
import { dataMerchant } from "../data/dataMerchant";
import { Country, Language, LinkId } from "../data/models/ContractTypes";
import { Status } from "../data/types";
import { useLinkId } from "../hooks/useLinkId";
import { contractState } from "../state/contractState";
import { DONE_PAGE_URL } from "./done/DonePage";
import { MISSING_PAGE_URL } from "./MissingPage";

interface Props {
  children: ReactNode;
}

export function defaultLanguageFromCountry(country?: Country) {
  switch (country) {
    case Country.BELGIUM:
      return Language.DUTCH;
    case Country.NETHERLANDS:
      return Language.DUTCH;
    case Country.FRANCE:
      return Language.FRENCH;
    case Country.CZECH_REPUBLIC:
      return Language.CZECH;
    case Country.LUXEMBURG:
      return Language.FRENCH;
    default:
      return Language.ENGLISH;
  }
}

export const ContractLoader: FunctionComponent<Props> = ({ children }) => {
  const [contract, setContract] = useRecoilState(contractState);
  const [status, setStatus] = useState<Status>(
    contract ? Status.SUCCESS : Status.DEFAULT
  );
  const linkId = useLinkId();
  const navigate = useNavigate();

  const load = useCallback(
    (idParam: LinkId) => {
      if (!idParam) {
        return;
      }
      setStatus(Status.PENDING);

      dataMerchant
        .getContract(idParam)
        .then((response) => {
          let lang;

          const viewerLanguage =
            response.language ||
            defaultLanguageFromCountry(response.country as Country);

          try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            ({ lang } = Object.fromEntries(urlSearchParams.entries()));
          } catch (err) {}

          i18n.changeLanguage(lang || viewerLanguage);

          setContract(response);
          setStatus(Status.SUCCESS);
        })
        .catch((err) => {
          if (err.status === 404) {
            navigate(MISSING_PAGE_URL);
            return;
          }

          if (err.status === 410) {
            navigate(DONE_PAGE_URL);
            return;
          }

          setStatus(Status.ERROR);
        });
    },
    [setContract, navigate]
  );

  useEffect(() => {
    if (contract) {
      return;
    }

    load(linkId);
  }, [load, contract, linkId]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(linkId);
    }, 500);
  }, [load, linkId]);

  if (!linkId) {
    navigate(MISSING_PAGE_URL);
    return null;
  }

  return (
    <div>
      <Retry retry={retry} status={status}>
        {contract && children}
      </Retry>
    </div>
  );
};
